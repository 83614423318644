<template>
  <!-- Loading -->
  <div v-if="loading">
    <br>
    <div class="d-flex justify-center">
      <v-progress-circular
        class="orange-spinner"
        indeterminate
      ></v-progress-circular>
    </div>      
  </div>
  <div v-else>
    <!-- Job -->
    <JobTitleComponent class="mb-2"></JobTitleComponent>
    <!-- Progress -->
    <v-row v-if="!store.isApplicationReview">
      <v-col>
        <ApplicationProgressComponent :phase="phase" :section="1" />
      </v-col>
    </v-row>
    <br>
    <!-- Alert -->
    <div 
      v-if="error"
      class="d-flex justify-center mb-5"
    >
      <v-alert
        border="start"
        type="error"
        variant="tonal"
      >
        {{ errorMessage }}
      </v-alert>
    </div>
    <!-- Section -->
    <v-row>
      <v-col>
        <div class="title-bold"> {{ isEnglish ? 'Personal Information' : 'Información Personal' }} </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-form
          @submit.prevent="save()"
        >
          <div v-if="phase === 0 || phase === 1">
            <!-- Message -->
            <div class="font-sm"> 
              <span v-if="isEnglish">
                All Labatt employees must be 18 years or older at the time of hire. 
                This information is used consistent with state and federal non-discrimination laws.
              </span>
              <span v-else>
                Todos los empleados de Labatt deben tener 18 años o más al momento de
                ser contratados. Esta información se utiliza de acuerdo con las leyes estatales y federales
                contra la discriminación.
              </span>
            </div>
            <br>
            <!-- Date of Birth -->
            <v-text-field
              v-model="app.dateOfBirth"
              :rules="dobRule"
              :readonly="loading"
              type="date"
              class="tf-padding"
              :label="isEnglish ? 'Date of birth*' : 'Fecha de nacimiento*'"
              variant="outlined"
              hide-details
            ></v-text-field>
            <!-- Social Security Number -->
            <v-text-field
              v-model="ssn"
              :rules="ssnRule"
              :readonly="loading"
              type="tel"
              class="tf-padding"
              :label="isEnglish ? 'Social security number*' : 'Número de seguro social*'"
              variant="outlined"
              v-mask="'###-##-####'"
              hide-details
            ></v-text-field>        
            <!-- Phone Number -->
            <v-text-field
              v-model="phoneNumber"
              :rules="phoneNumberRule"
              :readonly="loading"
              type="tel"
              class="tf-padding"
              :label="isEnglish ? 'Cell phone number*' : 'Número de teléfono celular*'"
              variant="outlined"
              v-mask="'(###) ###-####'"
              hide-details
            ></v-text-field>
            <!-- Alt Phone Number -->
            <v-text-field
              v-model="alternatePhoneNumber"
              :readonly="loading"
              type="tel"
              class="tf-padding"
              :label="isEnglish ? 'Alternate phone number' : 'Número de teléfono alternativo'"
              variant="outlined"
              v-mask="'(###) ###-####'"
              hide-details
            ></v-text-field>
          </div>
          <div v-if="requireMaritalStatus && (phase === 0 || phase === 2)">
            <!-- Marital Status -->
            <v-select
              v-model="app.maritalStatus"
              :rules="required"
              :readonly="loading"
              :items="[{ id: 'Unmarried', title: isEnglish ? 'Single' : 'Soltero(a)' }, { id: 'Married', title: isEnglish ? 'Married' : 'Casado(a)' }]"
              item-value="id"
              item-title="title"
              class="tf-padding"
              :label="isEnglish ? 'Marital status*' : 'Estado civil*'"
              variant="outlined"
              hide-details
            ></v-select>
            <!-- Spouse's Name -->
            <v-text-field
              v-model="app.spouseName"
              :readonly="loading"
              class="tf-padding"
              :label="isEnglish ? 'Spouse\'s name' : 'Nombre de esposo(a)'"
              variant="outlined"
              hide-details
            ></v-text-field>
            <!-- Children's Ages -->
            <v-text-field
              v-model="app.childrenAges"
              :readonly="loading"
              class="tf-padding"
              :label="isEnglish ? 'Children\'s ages' : 'Edades de hijos'"
              variant="outlined"
              hide-details
            ></v-text-field>
          </div>
          <div v-if="phase === 0 || phase === 1">
            <!-- Address - Section -->
            <hr class="mt-3 mb-3">
            <div class="mb-3"><b> {{ isEnglish ? 'Primary Residence' : 'Domicilio Principal' }} </b></div>
            <!-- Address -->
            <v-text-field
              v-model="app.primaryAddress1"
              :rules="required"
              :readonly="loading"
              class="tf-padding"
              :label="isEnglish ? 'Address*' : 'Dirección*'"
              variant="outlined"
              hide-details
            ></v-text-field>
            <!-- Apt -->
            <v-text-field
              v-model="app.primaryAddressApartmentNumber"
              :readonly="loading"
              class="tf-padding"
              :label="isEnglish ? 'Apt #' : 'Dpto #'"
              variant="outlined"
              hide-details
            ></v-text-field>
            <!-- City -->
            <v-text-field
              v-model="app.primaryAddressCity"
              :rules="required"
              :readonly="loading"
              class="tf-padding"
              :label="isEnglish ? 'City*' : 'Ciudad*'"
              variant="outlined"
              hide-details
            ></v-text-field>
            <!-- State -->
            <v-select
              v-model="app.primaryAddressState"
              :rules="required"
              :readonly="loading"
              :items="states"
              :item-title="isEnglish ? 'name' : 'spanishName'"
              item-value="id"
              class="tf-padding"
              :label="isEnglish ? 'State*' : 'Estado*'"
              variant="outlined"
              hide-details
            ></v-select>
            <!-- Zip Code -->
            <v-text-field
              v-model="app.primaryAddressZipCode"
              :rules="required"
              :readonly="loading"
              type="number"
              class="tf-padding"
              :label="isEnglish ? 'Zip code*' : 'Código postal*'"
              variant="outlined"
              v-mask="'#####'"
              hide-details
            ></v-text-field>
            <!-- How Long -->
            <div class="font-sm mb-3"> 
              {{ isEnglish ? 'How long have you lived at this address?*' : '¿Cuánto tiempo ha vivido en esta dirección?*' }}
            </div>
            <div class="d-flex">
              <v-text-field
                v-model="app.primaryAddressYears"
                :rules="addressYearsRule"
                :readonly="loading"
                type="number"
                width="10%"
                min="0"
                step="1"
                class="tf-padding mr-2"
                :label="isEnglish ? 'Years*' : 'Años*'"
                variant="outlined"
                v-mask="'##'"
                hide-details
              ></v-text-field>
              <v-text-field
                v-model="app.primaryAddressMonths"
                :readonly="loading"
                type="number"
                width="10%"
                min="0"
                step="1"
                class="tf-padding ml-2"
                :label="isEnglish ? 'Months' : 'Meses'"
                variant="outlined"
                v-mask="'##'"
                hide-details
              ></v-text-field>
            </div>
            <!-- Years of Residence -->
            <div class="font-sm mb-4"> 
              <span v-if="requireThreeYearsResidence">
                {{ isEnglish ? 
                   'All transportation and warehouse applicants must provide the most recent 3 years of residence.' : 
                   'Todos los solicitantes de transporte y almacén deben reportar 3 años de información de residencia.' }}
              </span>
              <span v-else>
                {{ isEnglish ? 
                   'All applicants must provide 1 year of residence information.' : 
                   'Todos los solicitantes deben reportar 1 año de información de residencia.' }}
              </span>
              <div 
                v-if="previousAddresses.length === 0" 
                class="d-flex justify-center"
              >
                <v-btn 
                  class="btn mt-3 mb-2" 
                  variant="tonal"
                  elevation="0"
                  @click="addPreviousAddress()"
                >
                  {{ isEnglish ? 'Add previous address' : 'Agregar una dirección previa' }}
                </v-btn>
              </div>
            </div>
            <!-- Previous Addresses - Section -->
            <div v-if="previousAddresses.length > 0">
              <hr class="mb-3">
              <div v-for="p in previousAddresses">
                <v-row>
                  <v-col cols="8">
                    <div class="mt-3 mb-3">
                      <b> 
                        <span>
                          {{ isEnglish ? 'Previous Residence ' : 'Domicilio Previo' }} ({{ previousAddresses.indexOf(p) + 1 }})
                        </span> 
                      </b>
                    </div>
                  </v-col>
                  <v-col>
                    <div class="d-flex justify-end">
                      <v-btn 
                        class="btn mt-1" 
                        variant="tonal"
                        elevation="0"
                        @click="deletePreviousAddress(p)"
                      >
                        {{ isEnglish ? 'Delete' : 'Eliminar' }}
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <!-- Address -->
                <v-text-field
                  v-model="p.address1"
                  :rules="required"
                  :readonly="loading"
                  class="tf-padding"
                  :label="isEnglish ? 'Address*' : 'Dirección*'"
                  variant="outlined"
                  hide-details
                ></v-text-field>
                <!-- Apt -->
                <v-text-field
                  v-model="p.apartmentNumber"
                  :readonly="loading"
                  class="tf-padding"
                  :label="isEnglish ? 'Apt #' : 'Dpto #'"
                  variant="outlined"
                  hide-details
                ></v-text-field>
                <!-- City -->
                <v-text-field
                  v-model="p.city"
                  :rules="required"
                  :readonly="loading"
                  class="tf-padding"
                  :label="isEnglish ? 'City*' : 'Ciudad*'"
                  variant="outlined"
                  hide-details
                ></v-text-field>
                <!-- State -->
                <v-select
                  v-model="p.state"
                  :rules="required"
                  :readonly="loading"
                  :items="states"
                  :item-title="isEnglish ? 'name' : 'spanishName'"
                  item-value="id"
                  class="tf-padding"
                  :label="isEnglish ? 'State*' : 'Estado*'"
                  variant="outlined"
                  hide-details
                ></v-select>
                <!-- Zip Code -->
                <v-text-field
                  v-model="p.zipCode"
                  :rules="required"
                  :readonly="loading"
                  type="number"
                  class="tf-padding"
                  :label="isEnglish ? 'Zip code*' : 'Código postal*'"
                  variant="outlined"
                  v-mask="'#####'"
                  hide-details
                ></v-text-field>
                <!-- How Long -->
                <div class="font-sm mb-3"> 
                  {{ isEnglish ? 'How long did you live at this address?*' : '¿Cuánto tiempo vivió en esta dirección?*' }}
                </div>
                <div class="d-flex">
                  <v-text-field
                    v-model="p.years"
                    :rules="required"
                    :readonly="loading"
                    type="number"
                    width="10%"
                    min="0"
                    step="1"
                    class="tf-padding mr-2"
                    :label="isEnglish ? 'Years*' : 'Años*'"
                    variant="outlined"
                    hide-details
                  ></v-text-field>
                  <v-text-field
                    v-model="p.months"
                    :readonly="loading"
                    type="number"
                    width="10%"
                    min="0"
                    step="1"
                    class="tf-padding ml-2"
                    :label="isEnglish ? 'Months' : 'Meses'"
                    variant="outlined"
                    hide-details
                  ></v-text-field>
                </div>
                <div 
                  v-if="previousAddresses.indexOf(p) === previousAddresses.length - 1" 
                  class="d-flex justify-center"
                >
                  <v-btn 
                    class="btn mt-2 mb-3"  
                    variant="tonal"
                    elevation="0"
                    @click="addPreviousAddress()"
                  >
                    {{ isEnglish ? 'Add another address' : 'Agregar otra dirección' }}
                  </v-btn>
                </div>
              </div>              
            </div>
            <!-- Not Enough Address Info -->
            <div 
              v-if="notEnoughAddressInfo"
              class="d-flex" 
            >
              <v-checkbox 
                v-model="app.noMorePreviousAddresses"
                :rules="required"
                :readonly="loading"
                class="tf-padding mr-2"
                hide-details
              ></v-checkbox>
              <div class="mt-4"> 
                {{ isEnglish ? 
                   'I certify that I have entered all previous address information available.' : 
                   'Certifico que he ingresado toda la información de residencia disponible.' }}
              </div>           
            </div>
            <!-- DL - Section -->
            <hr class="mt-3 mb-3">
            <div class="mb-3">
              <b> 
                {{ isEnglish ? 'Driver\'s License or State ID' : 'Licencia o Identificación Estatal' }} 
              </b>
            </div>   
            <!-- Driver's License ID -->
            <v-text-field
              v-model="app.driverLicenseNumber"
              :rules="required"
              :readonly="loading"
              class="tf-padding"
              :label="isEnglish ? 'ID number*' : 'Número de identificación*'"
              variant="outlined"
              v-mask="'XXXXXXXXXXXXXXXXXXXX'"
              hide-details
            ></v-text-field>
            <!-- Driver's License State -->
            <v-select
              v-model="app.driverLicenseState"
              :rules="required"
              :readonly="loading"
              :items="states"
              :item-title="isEnglish ? 'name' : 'spanishName'"
              item-value="id"
              class="tf-padding"
              :label="isEnglish ? 'State*' : 'Estado*'"
              variant="outlined"
              hide-details
            ></v-select>
            <!-- Driver's License Type -->
            <v-select
              v-model="app.driverLicenseType"
              :rules="required"
              :readonly="loading"
              :items="licenseTypes"
              :item-title="isEnglish ? 'name' : 'spanishName'"
              item-value="id"
              class="tf-padding"
              :label="isEnglish ? 'Type*' : 'Tipo de licencia*'"
              variant="outlined"
              hide-details
            ></v-select>
            <!-- Driver's License Expiration -->
            <v-text-field
              v-model="app.driverLicenseExpirationDate"
              :rules="driversLicenseExpirationDateRule"
              :readonly="loading"
              type="date"
              class="tf-padding"
              :label="isEnglish ? 'Expires on*' : 'Fecha de vencimiento*'"
              variant="outlined"
              hide-details
            ></v-text-field>  
          </div>
          <div v-if="phase === 0 || phase === 2">
            <!-- Emergency Contact - Section -->
            <hr class="mt-3 mb-3">
            <div class="mb-3"><b> {{ isEnglish ? 'Emergency Contact' : 'Contacto de Emergencia' }} </b></div>
            <!-- Emergency Name -->
            <v-text-field
              v-model="app.emergencyContactName"
              :rules="required"
              :readonly="loading"
              class="tf-padding"
              :label="isEnglish ? 'Name*' : 'Nombre*'"
              variant="outlined"
              hide-details
            ></v-text-field>
            <!-- Emergency Phone Number -->
            <v-text-field
              v-model="emergencyContactPhoneNumber"
              :rules="emergencyContactPhoneNumberRule"
              :readonly="loading"
              type="tel"
              class="tf-padding"
              :label="isEnglish ? 'Cell phone number*' : 'Número de teléfono celular*'"
              variant="outlined"
              v-mask="'(###) ###-####'"
              hide-details
            ></v-text-field>
            <!-- Emergency Address -->
            <v-text-field
              v-model="app.emergencyContactAddress1"
              :readonly="loading"
              class="tf-padding"
              :label="isEnglish ? 'Address' : 'Dirección'"
              variant="outlined"
              hide-details
            ></v-text-field>
            <!-- Emergency Apt -->
            <v-text-field
              v-model="app.emergencyContactApartmentNumber"
              :readonly="loading"
              class="tf-padding"
              :label="isEnglish ? 'Apt #' : 'Dpto #'"
              variant="outlined"
              hide-details
            ></v-text-field>
            <!-- Emergency City -->
            <v-text-field
              v-model="app.emergencyContactCity"
              :readonly="loading"
              class="tf-padding"
              :label="isEnglish ? 'City' : 'Ciudad'"
              variant="outlined"
              hide-details
            ></v-text-field>
            <!-- Emergency State -->
            <v-select
              v-model="app.emergencyContactState"
              :readonly="loading"
              :items="states"
              :item-title="isEnglish ? 'name' : 'spanishName'"
              item-value="id"
              class="tf-padding"
              :label="isEnglish ? 'State' : 'Estado'"
              variant="outlined"
              hide-details
            ></v-select>
            <!-- Emergency Zip Code -->
            <v-text-field
              v-model="app.emergencyContactZipCode"
              :readonly="loading"
              type="number"
              class="tf-padding"
              :label="isEnglish ? 'Zip code' : 'Código postal'"
              variant="outlined"
              v-mask="'#####'"
              hide-details
            ></v-text-field>
          </div>             
          <br>
          <!-- Btns -->
          <ApplicationNavigationComponent 
            :loading="loading" 
            @exit="save(true)"
            @previous="previous()"
          >
          </ApplicationNavigationComponent>
        </v-form>
      </v-col>
    </v-row>
  </div>  
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/stores/store'
import { useCookies } from 'vue3-cookies'
import ApplicationProgressComponent from '@/components/ApplicationProgressComponent.vue'
import ApplicationNavigationComponent from '@/components/ApplicationNavigationComponent.vue'
import JobTitleComponent from '@/components/JobTitleComponent.vue'
import api from '@/api'
import { LicenseType, State, Address, ApplicationPersonalInfo, ApplicationSection } from '@/types'

const loading = ref(false)
const error = ref(false)
const errorMessage = ref('')
const required = [(value: string) => !!value || 'Required']
const dobRule = [() => isDateOfBirthValid(false) || 'Invalid']
const ssnRule = [() => isSocialSecurityNumberValid(false) || 'Invalid']
const phoneNumberRule = [() => isPhoneNumberValid(false) || 'Invalid']
const addressYearsRule = [() => isPrimaryAddressYearsValid(false) || 'Invalid']
const driversLicenseExpirationDateRule = [() => isDriverLicenseExpirationDateValid(false) || 'Invalid']
const emergencyContactPhoneNumberRule = [() => isEmergencyContactPhoneNumberValid(false) || 'Invalid']
const router = useRouter()
const store = useStore()
const { cookies } = useCookies()
const email = ref('')
const licenseTypes = ref([] as LicenseType[])
const states = ref([] as State[])
const app = ref({} as ApplicationPersonalInfo)
const ssn = ref('')
const phoneNumber = ref('')
const alternatePhoneNumber = ref('')
const emergencyContactPhoneNumber = ref('')
const previousAddresses = ref([] as Address[])
const requireMaritalStatus = ref(true)
const notEnoughAddressInfo = ref(false)

const isEnglish = computed(() => {
  return store.language === 'english'
})

const phase = computed(() => {
  return store.applicationPhase
})

const requireThreeYearsResidence = computed(() => {
  return store.selectedJobPosting?.positionType === 'Warehouse' || 
    store.selectedJobPosting?.positionType === 'Transportation'
})

const reload = async () => {
  const userEmail = cookies.get('labatt-user-email')
  if (userEmail) {
    email.value = userEmail
  } else {
    router.push('/login')
  }
  window.scrollTo({ top: 0, behavior: 'smooth' })
  loading.value = true
  error.value = false
  errorMessage.value = ''
  try {
    const r = await api.getOffices()
    if (r) {
      const office = r.find(o => o.code == store.selectedJobPosting?.officeCode)
      if (office?.requireMaritalStatus) {
        requireMaritalStatus.value = true
      } else {
        requireMaritalStatus.value = false
      }
    } else {
      showError(isEnglish.value ? 'Error loading form' : 'Error al cargar el formulario')
    }
    const r1 = await api.getLicenseTypes()
    if (r1) {
      licenseTypes.value = r1
    } else {
      showError(isEnglish.value ? 'Error loading form' : 'Error al cargar el formulario')
    }
    const r2 = await api.getStates()
    if (r2) {
      states.value = r2
    } else {
      showError(isEnglish.value ? 'Error loading form' : 'Error al cargar el formulario')
    }
    if (store.applicationId) {
      const r3 = await api.getApplicationPersonalInfo(store.applicationId)
      if (r3) {
        app.value = r3
        if (app.value.socialSecurityNumber) {
          ssn.value = formatSSN(app.value.socialSecurityNumber)
        }
        if (app.value.phoneNumber) {
          const p = app.value.phoneNumber.toString()
          phoneNumber.value = '(' + p.slice(0, 3) + ') ' + p.slice(3, 7) + '-' + p.slice(7, 10)
        }
        if (app.value.alternatePhoneNumber) {
          const p = app.value.alternatePhoneNumber.toString()
          alternatePhoneNumber.value = '(' + p.slice(0, 3) + ') ' + p.slice(3, 7) + '-' + p.slice(7, 10)
        }
        if (app.value.previousAddresses) {
          previousAddresses.value = app.value.previousAddresses
        }
        if (app.value.emergencyContactPhoneNumber) {
          const p = app.value.emergencyContactPhoneNumber.toString()
          emergencyContactPhoneNumber.value = '(' + p.slice(0, 3) + ') ' + p.slice(3, 7) + '-' + p.slice(7, 10)
        }
        if (app.value.emergencyContactZipCode === 0) {
          app.value.emergencyContactZipCode = null
        }
      } else {
        app.value.id = store.applicationId
      } 
    }
  } catch (e) { 
    showError(isEnglish.value ? 'Please reload the page and try again' : 'Por favor actualice la página y vuelva a intentarlo')
  } finally {
    loading.value = false
  }
}
onMounted(reload)

const previous = async () => {
  if (store.isApplicationReview) {
    router.push('/application/review')
  } else {
    router.push('/dashboard')
  }
}

const showError = (message: string) => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  error.value = true
  errorMessage.value = message
  loading.value = false
}

const isDateOfBirthValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Date of birth is invalid' : 'Fecha de nacimiento no es válida'
  if (app.value.dateOfBirth) {
    const date = new Date(app.value.dateOfBirth)
    if (date) {
      const today = new Date()
      const isGreaterThan100Years = today.getFullYear() - date.getFullYear() >= 100
      const isFutureOrLessThan10Years = today.getFullYear() - date.getFullYear() <= 10
      if (isGreaterThan100Years || isFutureOrLessThan10Years) {
        if (alert) {
          showError(msg)
        }
        return false
      } 
      return true
    } else {
      if (alert) {
        showError(msg)
      }
      return false
    }
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isSocialSecurityNumberValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Social security number is invalid' : 'Número de seguro social no es válido'
  if (ssn.value) {
    const pattern = new RegExp('^\\d{3}-?\\d{2}-?\\d{4}$')
    if(!ssn.value.match(pattern)) {
      if (alert) {
        showError(msg)
      }
      return false
    }
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isPhoneNumberValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Cell phone number is invalid' : 'Número de teléfono celular no es válido'
  if (phoneNumber.value) {
    const phone = phoneNumber.value.toString()
    const pattern = new RegExp('^\\(?\\d{3}\\)?\\s ?\\d{3}-\\d{4}$')
    if(!phone.match(pattern)) {
      if (alert) {
        showError(msg)
      }
      return false
    }
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isPrimaryAddressValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Primary residence address is invalid' : 'Dirección de domicilio principal no es válido'
  if (app.value.primaryAddress1) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isPrimaryAddressCityValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Primary residence city is invalid' : 'Ciudad de domicilio principal no es válida'
  if (app.value.primaryAddressCity) {
    return true
  } else {
    if(alert) {
      showError(msg)
    }
    return false
  }
}

const isPrimaryAddressStateValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Primary residence state is invalid' : 'Estado de domicilio principal no es válido'
  if (app.value.primaryAddressState) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isPrimaryAddressZipCodeValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Primary residence zip code is invalid' : 'Código postal de domicilio principal no es válido'
  if (app.value.primaryAddressZipCode) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isPrimaryAddressYearsValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 
    'Primary residence years is invalid' : 
    'Años de domicilio principal no es válido'

  const msg2 = isEnglish.value ? 'You have not entered sufficient address information. ' +
    'If you have more address information, add another previous address. ' + 
    'Otherwise, select the box below.' : 'No ha llenado suficiente información de residencia. ' +
    'Si tiene más información de residencia, seleccione agregar otra dirección. Si no es así, seleccione abajo.'

  if (app.value.noMorePreviousAddresses) {
    return true
  }
  if (app.value.primaryAddressYears) {
    if (previousAddresses.value.length) {
      return true
    }
    if (requireThreeYearsResidence) {
      if (app.value.primaryAddressYears < 3) {
        if (alert) {
          notEnoughAddressInfo.value = true
          showError(msg2)
        }
        return false
      }      
    } else {
      if (app.value.primaryAddressYears < 1) {
        if (alert) {
          notEnoughAddressInfo.value = true
          showError(msg2)
        }
        return false
      } 
    }
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isPreviousAddressValid = (alert: boolean) : boolean => {
  if (previousAddresses.value.length) {
    let isValid = true
    previousAddresses.value.every((p : Address) => {
      if (p.address1) {
        // do nothing
      } else {
        isValid = false
        if (alert) {
          showError(isEnglish.value ? 'Previous residence address is invalid' : 'Dirección de domicilio previo no es válido')
        }
        return false
      }
      if (p.city) {
        // do nothing
      } else {
        isValid = false
        if (alert) {
          showError(isEnglish.value ? 'Previous residence city is invalid' : 'Ciudad de domicilio previo no es válida')
        }
        return false
      }
      if (p.state) {
        // do nothing
      } else {
        isValid = false
        if (alert) {
          showError(isEnglish.value ? 'Previous residence state is invalid' : 'Estado de domicilio previo no es válido')
        }
        return false
      }      
      if (p.zipCode) {
        // do nothing
      } else {
        isValid = false
        if (alert) {
          showError(isEnglish.value ? 'Previous residence zip code is invalid' : 'Código postal de domicilio previo no es válido')
        }
        return false
      }
      if (p.years || p.years === 0) {
        // do nothing
      } else {
        isValid = false
        if (alert) {
          showError(isEnglish.value ? 'Previous residence years is invalid' : 'Años de domicilio previo no es válido')
        }
        return false
      }
      return true
    })    
    if(!isValid) {
      return false
    }   
  }
  return true 
}

const isDriverLicenseNumberValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Driver license or state ID number is invalid' : 'Número de licencia o identificación estatal no es válido'
  if (app.value.driverLicenseNumber) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isDriverLicenseStateValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Driver license or state ID state is invalid' : 'Estado de licencia o identificación estatal no es válido'
  if (app.value.driverLicenseState) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isDriverLicenseTypeValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Driver license or state ID type is invalid' : 'Tipo de licencia o identificación estatal no es válido'
  if (app.value.driverLicenseType) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isDriverLicenseExpirationDateValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 
    'Driver license or state ID expires on is invalid' : 
    'Fecha de vencimiento de licencia o identificación estatal no es válido'

  const msg2 = isEnglish.value ? 
    'Expiration date may not be in the past. Applicant must have a valid current ID.' : 
    'La fecha de vencimiento no puede ser en el pasado. El solicitante debe tener una identificación vigente y válida.'

  if (app.value.driverLicenseExpirationDate) {
    const date = new Date(app.value.driverLicenseExpirationDate)
    if (date) {
      const today = new Date()
      if (date < today) {
        if (alert) {
          showError(msg2)
        }
        return false
      } 
      return true
    } else {
      if (alert) {
        showError(msg)
      }
      return false
    }
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isMaritalStatusValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Marital status is invalid' : 'Estado civil no es válido'
  if (!requireMaritalStatus.value || app.value.maritalStatus) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isEmergencyContactNameValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Emergency contact name is invalid' : 'Nombre del contacto de emergencia no es válido'
  if (app.value.emergencyContactName) {
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isEmergencyContactPhoneNumberValid = (alert: boolean) : boolean => {
  const msg = isEnglish.value ? 'Emergency contact cell phone number is invalid' : 'Número de teléfono del contacto de emergencia no es válido'
  if (emergencyContactPhoneNumber.value) {
    const phone = emergencyContactPhoneNumber.value.toString()
    const pattern = new RegExp('^\\(?\\d{3}\\)?\\s ?\\d{3}-\\d{4}$')
    if(!phone.match(pattern)) {
      if (alert) {
        showError(msg)
      }
      return false
    }
    return true
  } else {
    if (alert) {
      showError(msg)
    }
    return false
  }
}

const isPhaseOneValid = () : boolean => {
  return isDateOfBirthValid(true) &&
    isSocialSecurityNumberValid(true) &&
    isPhoneNumberValid(true) &&
    isPrimaryAddressValid(true) && 
    isPrimaryAddressCityValid(true) && 
    isPrimaryAddressStateValid(true) &&
    isPrimaryAddressZipCodeValid(true) &&
    isPrimaryAddressYearsValid(true) &&
    isPreviousAddressValid(true) && 
    isDriverLicenseNumberValid(true) &&
    isDriverLicenseStateValid(true) &&
    isDriverLicenseTypeValid(true) &&
    isDriverLicenseExpirationDateValid(true)
}

const isPhaseTwoValid = () : boolean => {
  return isMaritalStatusValid(true) &&
    isEmergencyContactNameValid(true) &&
    isEmergencyContactPhoneNumberValid(true)
}

const areBothPhasesValid = () : boolean => {
  return isDateOfBirthValid(true) &&
    isSocialSecurityNumberValid(true) &&
    isPhoneNumberValid(true) &&
    isMaritalStatusValid(true) &&
    isPrimaryAddressValid(true) && 
    isPrimaryAddressCityValid(true) && 
    isPrimaryAddressStateValid(true) &&
    isPrimaryAddressZipCodeValid(true) &&
    isPrimaryAddressYearsValid(true) &&
    isPreviousAddressValid(true) && 
    isDriverLicenseNumberValid(true) &&
    isDriverLicenseStateValid(true) &&
    isDriverLicenseTypeValid(true) &&
    isDriverLicenseExpirationDateValid(true) &&
    isEmergencyContactNameValid(true) &&
    isEmergencyContactPhoneNumberValid(true)
}

const isFormValid = () : boolean => {
  if (phase.value === 1) {
    return isPhaseOneValid()
  } 
  if (phase.value === 2) {
    return isPhaseTwoValid()
  }
  return areBothPhasesValid()
}

const convertToNumber = (v: string) : number => {
  if (v) {
    return parseInt(v.replace(/[^0-9]/g, ''))
  } 
  return 0
}

const formatSSN = (s: number | null) => {
  if (s) {
    const str = s.toString()
    if (str.length === 7) {
      return '00' + str.substring(0, 1) + '-' + str.substring(1, 3) + '-' + str.substring(3, 7)
    } else if (str.length === 8) {
      return '0' + str.substring(0, 2) + '-' + str.substring(2, 4) + '-' + str.substring(4, 8)
    } else {
      return str.substring(0, 3) + '-' + str.substring(3, 5) + '-' + str.substring(5, 9)
    }
  }
  return ''
}

const save = async (exit?: boolean) => {
  loading.value = true
  error.value = false
  errorMessage.value = ''
  if (isFormValid()) {
    app.value.email = email.value 
    app.value.socialSecurityNumber = convertToNumber(ssn.value)
    app.value.phoneNumber = convertToNumber(phoneNumber.value)
    app.value.alternatePhoneNumber = convertToNumber(alternatePhoneNumber.value)
    if (app.value.primaryAddressMonths) {
      // do nothing
    } else {
      app.value.primaryAddressMonths = 0
    }
    app.value.previousAddresses = previousAddresses.value
    if (app.value.previousAddresses.length) {
      app.value.previousAddresses.forEach((a: Address) => {
        if (a.months) {
          // do nothing
        } else {
          a.months = 0
        }
      })
    }
    app.value.emergencyContactPhoneNumber = convertToNumber(emergencyContactPhoneNumber.value)
    if (app.value.emergencyContactZipCode) {
      // do nothing
    } else {
      app.value.emergencyContactZipCode = 0
    }
    app.value.language = store.language
    try {
      var r = await api.updateApplicationPersonalInfo(app.value)
      if (r === 'Success') {
        var s : ApplicationSection = {
          id: 'personal-information',
          title: 'Personal Information',
          isReviewed: false
        }
        var sections = store.applicationSections
        if (!sections.map(ss => ss.id).includes(s.id)) {
          sections.push(s)
          store.setApplicationSections(sections)
        }
        if (exit) {
          router.push('/application/dashboard')
        } else if (store.isApplicationReview) {
          router.push('/application/review')
        } else {
          router.push('/application/work-eligibility')
        }
      } else {
        if (r.includes('You have submitted an application within the previous 90 days') || r.includes('Usted ha enviado una aplicación en los últimos 90 días')) {
          r += isEnglish.value ? '. You will be redirected to the dashboard page.' : '. Usted será dirigido a la página de inicio.'
          setTimeout(() => {
            router.push('/application/dashboard')
          }, 5000)
        }
        showError(r)
      }
    } catch (e) {
      showError(isEnglish.value ? 'Error saving personal info' : 'Error al guardar la información personal')
    } 
  }   
}

const addPreviousAddress = () => {
  var address : Address = {
    address1: null,
    address2: null,
    apartmentNumber: null,
    city: null,
    state: null,
    zipCode: null,
    phoneNumber: 0,
    alternatePhoneNumber: 0,
    years: null,
    months: null,
  }
  previousAddresses.value.push(address)
}

const deletePreviousAddress = (p: Address) => {
  const i = previousAddresses.value.indexOf(p)
  if (i > -1) { 
    previousAddresses.value.splice(i, 1)
  }
}
</script>

<style>

</style>
