<template>
  <v-row>
    <v-col>
      <div class="d-flex justify-start">
        <b> {{ phase === 1 ? '' : props.view }} </b>
      </div>
    </v-col>
    <v-col 
      cols="8"
      xl="6"
    >
      <div class="d-flex justify-end">
        <span 
          v-if="showDashboardLink"
        >
          <a 
            class="dashboard-link mr-4" 
            @click="dialog = true"
          > 
            <b> {{ isEnglish ? 'Dashboard' : 'Inicio' }} </b>
          </a>
          |
        </span>
        <a 
          class="logout ml-4" 
          @click="logout()"
          :href="'#/login'"
        > 
          <b> {{ isEnglish ? 'Log out' : 'Cerrar sesión' }} </b>
        </a>
      </div>
    </v-col>
  </v-row>
  <!-- Dashboard - Dialog -->
  <v-dialog
    v-model="dialog"
    width="auto"
  >
    <v-card
      max-width="400"
      class="dialog-text"
      :text="isEnglish ? 
             'Your application is incomplete. If you go back to the Dashboard page, your information on this page will not be saved.' : 
             'Su aplicación está incompleta. Si regresa a la página de Inicio, la información en esta página no se guardará.'"
    >
      <template v-slot:actions>
        <v-btn
          class="btn orange-btn mr-4"
          :text="'Ok'"
          @click="goToDashboard()"
        ></v-btn>
        <v-btn
          class="btn orange-btn"
          :text="isEnglish ? 'Cancel' : 'Cancelar'"
          @click="dialog = false"
        ></v-btn>
      </template>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue'
import { useCookies } from 'vue3-cookies'
import { useStore } from '@/stores/store'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'

const { cookies } = useCookies()
const store = useStore()
const route = useRoute()
const router = useRouter()
const dialog = ref(false)

const props = defineProps({
  view: {
    type: String,
    required: true,
  },
})

const isEnglish = computed(() => {
  return store.language === 'english'
})

const phase = computed(() => {
  return store.applicationPhase
})

const showDashboardLink = computed(() => {
  if (route.path.includes('application') && route.name !== 'submitted') {
    return true
  }
  return false
})

const goToDashboard = () => {
  dialog.value = false
  router.push('/dashboard')
}

const logout = () => {
  cookies.remove('labatt-user-email')
  store.setCurrentEmail('')
  store.setTemporaryLanguage('')
  store.setLanguage('english')
  store.resetStore()
}
</script>

<style>
.dialog-text {
  font-family: 'Montserrat' !important;
  color: #0a2240 !important;
  font-size: 16px;
}
.logout:hover {                         
  color: #f37027 !important; 
}
.dashboard-link:hover {
  cursor: pointer;        
  color: #f37027 !important; 
}
</style>
