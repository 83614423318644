import * as z from 'zod'

export const CurrentUser = z.object({
  id: z.string(),
  username: z.string(),
  verificationCode: z.string(),
  wasEmailVerified: z.boolean(),
  verificationCodeTime: z.string(),
  emailVerifiedTime: z.string(),
  firstName: z.string(),
  middleName: z.string(),
  lastName: z.string(),
})
export type CurrentUser = z.infer<typeof CurrentUser>

export const NewUser = z.object({
  firstName: z.string(),
  middleName: z.string(),
  lastName: z.string(),
  email: z.string(),
  password: z.string(),
  confirmPassword: z.string(),
  language: z.string(),
})
export type NewUser = z.infer<typeof NewUser>

export const LoginUser = z.object({
  email: z.string(),
  password: z.string(),
  language: z.string(),
})
export type LoginUser = z.infer<typeof LoginUser>

export const ResetUserPassword = z.object({
  email: z.string(),
  confirmationCode: z.string(),
  password: z.string(),
  confirmPassword: z.string(),
  language: z.string(),
})
export type ResetUserPassword = z.infer<typeof ResetUserPassword>

export const HelpCategory = z.object({
  id: z.string(),
  categoryEnglish: z.string(),
  categorySpanish: z.string(),
})
export type HelpCategory = z.infer<typeof HelpCategory>

export const HelpUser = z.object({
  name: z.string(),
  email: z.string(),
  phoneNumber: z.string(),
  helpCategoryId: z.string(),
  issueDescription: z.string(),
  language: z.string(),
})
export type HelpUser = z.infer<typeof HelpUser>

export const JobPosting = z.object({
  id: z.string(),
  title: z.string(),
  isOpen: z.boolean(),
  officeCode: z.string(),
  positionType: z.string(),
  isInternship: z.boolean(),
  isResume: z.boolean(),
  isMultiPhase: z.boolean(),
})
export type JobPosting = z.infer<typeof JobPosting>

export const ApplicationInfo = z.object({
  id: z.string(),
  applicationDate: z.string(),
  status: z.string(),
  jobPostingId: z.string(),
  jobTitle: z.string(),
  officeCode: z.string(),
  location: z.string(),
  lastSavedPage: z.number(),
})
export type ApplicationInfo = z.infer<typeof ApplicationInfo>

export const NewApplication = z.object({
  email: z.string(),
  jobPostingId: z.string(),
  webHistory: z.string(),
  language: z.string(),
})
export type NewApplication = z.infer<typeof NewApplication>

export const CancelApplication = z.object({
  email: z.string(),
  applicationId: z.string().nullable(),
  language: z.string(),
})
export type CancelApplication = z.infer<typeof CancelApplication>

export const ContinueApplication = z.object({
  email: z.string(),
  applicationId: z.string().nullable(),
  language: z.string(),
})
export type ContinueApplication = z.infer<typeof ContinueApplication>

export const LicenseType = z.object({
  id: z.string(),
  name: z.string(),
  spanishName: z.string(),
})
export type LicenseType = z.infer<typeof LicenseType>

export const State = z.object({
  id: z.string(),
  name: z.string(),
  spanishName: z.string(),
})
export type State = z.infer<typeof State>

export const Office = z.object({
  code: z.string(),
  description: z.string(),
  requireMaritalStatus: z.boolean(),
  requireBackgroundQuestion: z.boolean(),
})
export type Office = z.infer<typeof Office>

export const Ethnicity = z.object({
  code: z.string(),
  shortCode: z.string(),
  description: z.string(),
  spanishDescription: z.string(),
})
export type Ethnicity = z.infer<typeof Ethnicity>

export const ReferralSource = z.object({
  code: z.number(),
  description: z.string(),
  spanishDescription: z.string(),
  needDetails: z.boolean(),
  offices: z.array(z.string()),
  isForResume: z.boolean(),
  isActive: z.boolean(),
})
export type ReferralSource = z.infer<typeof ReferralSource>

export const Address = z.object({
  address1: z.string().nullable(),
  address2: z.string().nullable(),
  apartmentNumber: z.string().nullable(),
  city: z.string().nullable(),
  state: z.string().nullable(),
  zipCode: z.number().nullable(),
  phoneNumber: z.number().nullable(),
  alternatePhoneNumber: z.number().nullable(),
  years: z.number().nullable(),
  months: z.number().nullable(),
})
export type Address = z.infer<typeof Address>

export const DriverLicense = z.object({
  state: z.string().nullable(),
  number: z.string().nullable(),
  type: z.string().nullable(),
  expirationDate: z.string().nullable(),
})
export type DriverLicense = z.infer<typeof DriverLicense>

export const AccidentRecord = z.object({
  dateOfAccident: z.string().nullable(),
  natureOfAccident: z.string().nullable(),
  fatalities: z.number().nullable(),
  injuries: z.number().nullable(),
})
export type AccidentRecord = z.infer<typeof AccidentRecord>

export const TrafficConviction = z.object({
  dateOfConviction: z.string().nullable(),
  location: z.string().nullable(),
  charge: z.string().nullable(),
  penalty: z.string().nullable(),
})
export type TrafficConviction = z.infer<typeof TrafficConviction>

export const DrivingExperience = z.object({
  equipmentClass: z.string().nullable(),
  equipmentType: z.string().nullable(),
  startDate: z.string().nullable(),
  endDate: z.string().nullable(),
  miles: z.number().nullable(),
})
export type DrivingExperience = z.infer<typeof DrivingExperience>

export const ApplicationPersonalInfo = z.object({
  id: z.string(),
  email: z.string().nullable(),
  dateOfBirth: z.string().nullable(),
  socialSecurityNumber: z.number().nullable(),
  phoneNumber: z.number().nullable(),
  alternatePhoneNumber: z.number().nullable(),
  maritalStatus: z.string().nullable(),
  spouseName: z.string().nullable(),
  childrenAges: z.string().nullable(),
  driverLicenseNumber: z.string().nullable(),
  driverLicenseState: z.string().nullable(),
  driverLicenseType: z.string().nullable(),
  driverLicenseExpirationDate: z.string().nullable(),
  primaryAddress1: z.string().nullable(),
  primaryAddress2: z.string().nullable(),
  primaryAddressApartmentNumber: z.string().nullable(),
  primaryAddressCity: z.string().nullable(),
  primaryAddressState: z.string().nullable(),
  primaryAddressZipCode: z.number().nullable(),
  primaryAddressYears: z.number().nullable(),
  primaryAddressMonths: z.number().nullable(),
  emergencyContactName: z.string().nullable(),
  emergencyContactPhoneNumber: z.number().nullable(),
  emergencyContactAddress1: z.string().nullable(),
  emergencyContactAddress2: z.string().nullable(),
  emergencyContactApartmentNumber: z.string().nullable(),
  emergencyContactCity: z.string().nullable(),
  emergencyContactState: z.string().nullable(),
  emergencyContactZipCode: z.number().nullable(),
  previousAddresses: z.array(Address).nullable(),
  noMorePreviousAddresses: z.boolean().nullable(),
  language: z.string().nullable(),
})
export type ApplicationPersonalInfo = z.infer<typeof ApplicationPersonalInfo>

export const ApplicationWorkEligibility = z.object({
  id: z.string(),
  email: z.string().nullable(),
  isAuthorized: z.boolean().nullable(),
  requireSponsorship: z.boolean().nullable(),
  potentialStartDate: z.string().nullable(),
  isEmployedNow: z.boolean().nullable(),
  bestShiftTime: z.array(z.string()).nullable(),
})
export type ApplicationWorkEligibility = z.infer<typeof ApplicationWorkEligibility>

export const ApplicationEducationMilitary = z.object({
  id: z.string(),
  email: z.string().nullable(),
  highSchoolName: z.string().nullable(),
  highSchoolCity: z.string().nullable(),
  highSchoolState: z.string().nullable(),
  highSchoolYearStarted: z.number().nullable(),
  highSchoolYearCompleted: z.number().nullable(),
  highSchoolGraduated: z.boolean().nullable(),
  highSchoolFinalYearCompleted: z.number().nullable(),  
  gedName: z.string().nullable(),
  gedCity: z.string().nullable(),
  gedState: z.string().nullable(),
  gedYearStarted: z.number().nullable(),
  gedYearCompleted: z.number().nullable(),
  gedGraduated: z.boolean().nullable(),  
  collegeUniversityName: z.string().nullable(),
  collegeUniversityCity: z.string().nullable(),
  collegeUniversityState: z.string().nullable(),
  collegeUniversityYearStarted: z.number().nullable(),
  collegeUniversityYearCompleted: z.number().nullable(),
  collegeUniversityGraduated: z.boolean().nullable(),
  collegeUniversityFinalYearCompleted: z.number().nullable(),
  collegeUniversityDegree: z.string().nullable(),
  extracurricularActivities: z.string().nullable(),
  specialSkills: z.string().nullable(),
  militaryNationalGuardOrReserve: z.string().nullable(),
  militaryServiceBranch: z.string().nullable(),
  militaryDateEntered: z.string().nullable(),
  militaryDateDischarged: z.string().nullable(),
  militarySpecialty: z.string().nullable(),
  militaryFinalRankOrRate: z.string().nullable(),
})
export type ApplicationEducationMilitary = z.infer<typeof ApplicationEducationMilitary>

export const PreviousJob = z.object({
  company: z.string().nullable(),
  jobTitle: z.string().nullable(),
  supervisorName: z.string().nullable(),
  supervisorPhoneNumber: z.number().nullable(),
  address: Address,
  responsibilities: z.string().nullable(),
  startDate: z.string().nullable(),
  endDate: z.string().nullable(),
  startingPay: z.number().nullable(),
  startingFrequency: z.string().nullable(),
  endingPay: z.number().nullable(),
  endingFrequency: z.string().nullable(),
  reasonForLeaving: z.string().nullable(),
  canContactEmployer: z.boolean().nullable(),
  isSubjectToFederalRegulations: z.boolean().nullable(),
  isSafetySensitiveFunction: z.boolean().nullable(),
})
export type PreviousJob = z.infer<typeof PreviousJob>

export const ApplicationPreviousEmployment = z.object({
  id: z.string(),
  email: z.string().nullable(),
  hasPreviousJobs: z.boolean().nullable(),
  jobs: z.array(PreviousJob).nullable(),
  noMoreJobs: z.boolean().nullable(),
})
export type ApplicationPreviousEmployment = z.infer<typeof ApplicationPreviousEmployment>

export const WorkExperience = z.object({
  code: z.string().nullable(),
  description: z.string().nullable(),
  location: z.string().nullable(),
  years: z.number().nullable(),
  months: z.number().nullable(),
})
export type WorkExperience = z.infer<typeof WorkExperience>

export const ApplicationWorkExperience = z.object({
  id: z.string(),
  email: z.string().nullable(),
  hasWorkExperience: z.boolean().nullable(),
  workExperiences: z.array(WorkExperience),
})
export type ApplicationWorkExperience = z.infer<typeof ApplicationWorkExperience>

export const ApplicationBackground = z.object({
  id: z.string(),
  email: z.string().nullable(),
  hasCriminalConvictions: z.boolean().nullable(),
  criminalConvictionDetails: z.string().nullable(),
  hasPriorTerminations: z.boolean().nullable(),
  priorTerminationDetails: z.string().nullable(),
  hasLicenseViolations: z.boolean().nullable(),
  licenseViolationDetails: z.string().nullable(),
  numberOfVehicularAccidents: z.number().nullable(),
  vehicularAccidentDetails: z.string().nullable(),
  numberOfMovingViolations: z.number().nullable(),
  movingViolationDetails: z.string().nullable(),
  hasFamilyAtLabatt: z.boolean().nullable(),
  familyAtLabattDetails: z.string().nullable(),
  getToWorkTransportation: z.string().nullable(),
  daysAndWeeksUnavailable: z.string().nullable(),
  isPreviousApplicant: z.boolean().nullable(),
  previousApplicantDate: z.string().nullable(),
  isPreviousEmployee: z.boolean().nullable(),
  previousEmployeeDate: z.string().nullable(),
  jobAttractions: z.array(z.string()).nullable(),
  referralSourceCode: z.number().nullable(),
  referralSourceDescription: z.string().nullable(),
  hasReferralSourceDetails: z.boolean().nullable(),
  referralSourceDetails: z.string().nullable(),
  whyIsJobImportant: z.string().nullable(),
})
export type ApplicationBackground = z.infer<typeof ApplicationBackground>

export const ApplicationDriverInformation = z.object({
  id: z.string(),
  email: z.string().nullable(),
  driverLicenses: z.array(DriverLicense).nullable(),
  hasLicenseDenied: z.boolean().nullable(),
  licenseDeniedDetails: z.string().nullable(),
  hasLicenseSuspended: z.boolean().nullable(),
  licenseSuspendedDetails: z.string().nullable(),
  hasAccidentRecord: z.boolean().nullable(),
  accidentRecords: z.array(AccidentRecord).nullable(),
  hasTrafficConviction: z.boolean().nullable(),
  trafficConvictions: z.array(TrafficConviction).nullable(),
  drivingExperiences: z.array(DrivingExperience).nullable()
})
export type ApplicationDriverInformation = z.infer<typeof ApplicationDriverInformation>

export const ApplicationEEOAA = z.object({
  id: z.string(),
  email: z.string().nullable(),
  isEEOAAOmitted: z.boolean().nullable(),
  ethnicityCode: z.string().nullable(),
  ethnicityDetails: z.string().nullable(),
  gender: z.string().nullable(),
  isDisabledOmitted: z.boolean().nullable(),
  isDisabled: z.boolean().nullable(),
  disabledSignature: z.string().nullable(),
  isVeteranOmitted: z.boolean().nullable(),
  isVeteran: z.boolean().nullable(),
  veteranSignature: z.string().nullable(),
  signedDate: z.string().nullable(),
})
export type ApplicationEEOAA = z.infer<typeof ApplicationEEOAA>

export const ApplicationSection = z.object({
  id: z.string(),
  title: z.string(),
  isReviewed: z.boolean(),
})
export type ApplicationSection = z.infer<typeof ApplicationSection>

export const ApplicationReview = z.object({
  id: z.string(),
  email: z.string().nullable(),
  isPersonalInformationReviewed: z.boolean().nullable(),
  isWorkEligibilityReviewed: z.boolean().nullable(),
  isEducationMilitaryReviewed: z.boolean().nullable(),
  isPreviousEmploymentReviewed: z.boolean().nullable(),
  isWorkExperienceReviewed: z.boolean().nullable(),
  isBackgroundReviewed: z.boolean().nullable(),
  isDriverInformationReviewed: z.boolean().nullable(),
  isEEOAAReviewed: z.boolean().nullable(),
  isApplicantVerificationSigned: z.boolean().nullable(),
  applicantVerificationDetails: z.string().nullable(),
  isApplicantCertificationSigned: z.boolean().nullable(),
  applicantCertificationDetails: z.string().nullable(),
})
export type ApplicationReview = z.infer<typeof ApplicationReview>

export const ApplicationAdditionalForms = z.object({
  id: z.string(),
  email: z.string().nullable(),
  isConsumerReportFormSigned: z.boolean().nullable(),
  consumerReportFormDate: z.string().nullable(),
  consumerReportFormSignature1: z.string().nullable(),
  consumerReportFormSignature2: z.string().nullable(),
  consumerReportFormSignature3: z.string().nullable(),
  consumerReportFormFullName: z.string().nullable(),
  consumerReportFormRequestCopyOfReports: z.boolean().nullable(),
  consumerReportFormInitialsToReceiveArticle23A: z.string().nullable(),
  isSummaryOfRightsFormSigned: z.boolean().nullable(),
  isPreviousEmployerFormSigned: z.boolean().nullable(),
  previousEmployerFormDate: z.string().nullable(),
  previousEmployerFormSignature: z.string().nullable(),
  previousEmployerFormFullName: z.string().nullable(),
  isInternal: z.boolean().nullable(),
  language: z.string().nullable(),
})
export type ApplicationAdditionalForms = z.infer<typeof ApplicationAdditionalForms>

export const QuickApply = z.object({
  firstName: z.string(),
  middleName: z.string().nullable(),
  lastName: z.string(),
  email: z.string(),
  phoneNumber: z.number(),
  preferredName: z.string().nullable(),
  referralSourceCode: z.number(),
  hasReferralSourceDetails: z.boolean().nullable(),
  referralSourceDetails: z.string().nullable(),
  isTimeRange1: z.boolean().nullable(),
  isTimeRange2: z.boolean().nullable(),
  isTimeRange3: z.boolean().nullable(),
  isTimeRangeOther: z.boolean().nullable(),
  timeRangeOtherDetails: z.string().nullable(),
  timeZone: z.string().nullable(),
  jobPostingId: z.string().nullable(),
  language: z.string(),
})
export type QuickApply = z.infer<typeof QuickApply>

export const College = z.object({
  id: z.string(),
  name: z.string().nullable(),
  address: z.string().nullable(),
  city: z.string().nullable(),
  state: z.string().nullable(),
  fullName: z.string().nullable(),
})
export type College = z.infer<typeof College>

export const Resume = z.object({
  firstName: z.string(),
  middleName: z.string().nullable(),
  lastName: z.string(),
  email: z.string(),
  phoneNumber: z.number(),
  preferredName: z.string().nullable(),
  referralSourceCode: z.number(),
  hasReferralSourceDetails: z.boolean().nullable(),
  referralSourceDetails: z.string().nullable(),
  hasCollegeEducation: z.boolean().nullable(),
  collegeId: z.string().nullable(),
  isOtherCollege: z.boolean().nullable(),
  otherCollegeName: z.string().nullable(),
  isInternship: z.boolean().nullable(),
  expectedGraduationMonth: z.number().nullable(),
  expectedGraduationYear: z.number().nullable(),
  jobPostingId: z.string().nullable(),
})
export type Resume = z.infer<typeof Resume>

export const FileDoc = z.object({
  email: z.string(),
  fileName: z.string().nullable(),
  fileType: z.string().nullable(),
  fileContents: z.string().nullable(),
  jobPostingId: z.string().nullable(),
  userId: z.string().nullable(),
})
export type FileDoc = z.infer<typeof FileDoc>

export const ActivityAnswer = z.object({
  code: z.string().nullable(),
  englishDescription: z.string().nullable(),
  spanishDescription: z.string().nullable(),
  value: z.number().nullable(),
})
export type ActivityAnswer = z.infer<typeof ActivityAnswer>

export const ActivityQuestion = z.object({
  id: z.string(),
  activityId: z.string().nullable(),
  sequence: z.number().nullable(),
  englishQuestion: z.string().nullable(),
  spanishQuestion: z.string().nullable(),
  image1: z.string().nullable(),
  image2: z.string().nullable(),
  answers: z.array(ActivityAnswer).nullable(),
  isPractice: z.boolean().nullable(),
  numberOfSelections: z.number().nullable(),
  answerImage1: z.string().nullable(),
  answerImage2: z.string().nullable(),
  answerImage3: z.string().nullable(),
})
export type ActivityQuestion = z.infer<typeof ActivityQuestion>

export const Activity = z.object({
  id: z.string().nullable(),
  name: z.string().nullable(),
  spanishName: z.string().nullable(),
  englishInstructions: z.string().nullable(),
  spanishInstructions: z.string().nullable(),
  type: z.string().nullable(),
  hasPracticeQuestions: z.boolean().nullable(),
  practiceQuestions: z.array(ActivityQuestion).nullable(),
  testQuestions: z.array(ActivityQuestion).nullable(),
  hasTimeLimit: z.boolean().nullable(),
  maxTime: z.number().nullable(),
})
export type Activity = z.infer<typeof Activity>

export const SaveActivity = z.object({
  email: z.string(),
  applicationId: z.string(),
  activityId: z.string().nullable(),
  questionId: z.string().nullable(),
  finishedPractice: z.boolean().nullable(),
  type: z.string().nullable(),
  isLast: z.boolean().nullable(),
  answers: z.array(z.string()).nullable(),
  startTime: z.date().nullable(),
})
export type SaveActivity = z.infer<typeof SaveActivity>

export const AccountInformation = z.object({
  firstName: z.string(),
  middleName: z.string(),
  lastName: z.string(),
  email: z.string(),
  currentPassword: z.string(),
  newPassword: z.string(),
  confirmNewPassword: z.string(),
  language: z.string(),
})
export type AccountInformation = z.infer<typeof AccountInformation>

export const MultiPhaseApplicant = z.object({
  id: z.string(),
  applicationId: z.string(),
  status: z.string(),
})
export type MultiPhaseApplicant = z.infer<typeof MultiPhaseApplicant>