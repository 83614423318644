<template>
  <!-- Loading -->
  <div v-if="loading">
    <br>
    <div class="d-flex justify-center">
      <v-progress-circular
        class="orange-spinner"
        indeterminate
      ></v-progress-circular>
    </div>      
  </div>
  <div v-else>
    <!-- Job -->
    <JobTitleComponent class="mb-2"></JobTitleComponent>
    <!-- Progress -->
    <v-row>
      <v-col>
        <ApplicationProgressComponent :phase="phase" :section="section" />
      </v-col>
    </v-row>
    <br>
    <!-- Alert -->
    <div 
      v-if="error"
      class="d-flex justify-center mb-5"
    >
      <v-alert
        border="start"
        type="error"
        variant="tonal"
      >
        {{ errorMessage }}
      </v-alert>    
    </div>
    <!-- Section -->
    <v-row>
      <v-col>
        <div class="title-bold"> {{ isEnglish ? 'Additional Forms' : 'Formas Adicionales' }} </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-form
          @submit.prevent="saveAndContinue()"
        >
          <!-- Message -->
          <div class="font-sm mb-6"> 
            {{ isEnglish ? 
               'Select each document to review. Do not submit until you have reviewed and/or signed all documents.' : 
               'Seleccione cada documento para revisar. No continúe hasta que haya revisado y/o firmado todos los documentos.' }}
          </div>
          <!-- Panels -->
          <v-expansion-panels 
            v-model="panel"
            multiple
          >
            <!-- Consumer Report -->
            <v-expansion-panel>
              <v-expansion-panel-title> 
                <v-icon
                  v-if="app.isConsumerReportFormSigned"
                  class="mr-3 success-color"
                  icon="mdi-check-bold"
                  title="Signed"
                ></v-icon>
                {{ isEnglish ? 
                   'Disclosure and Authorization of Consumer Reports' : 
                   'Formulario de Consentimiento y Autorización de Investigación para Reportes del Consumidor' }}
              </v-expansion-panel-title>
              <v-expansion-panel-text class="font-sm">
                <br>
                <div v-if="isEnglish"> Please read the following form. Fill out the required information and sign below. </div>
                <div v-else> Por favor, lea el siguiente formulario. Llene la información requerida y firme el formulario. </div>
                <br>
                <div><b> {{ isEnglish ? 'AUTHORIZATION' : 'AUTORIZACIÓN' }} </b></div>
                <br>
                <div v-if="isEnglish">
                  I hereby authorize procurement of consumer report(s) and investigative consumer report(s) listed in the Disclosure by 
                  <u> Labatt Food Service </u> (""Company"") and its consumer reporting agency Integrated Screening Partners. If hired
                  (or contracted), this authorization shall remain on file and shall serve as ongoing authorization for Company to 
                  procure such reports at any time during, as permitted by law, my employment, contract, or volunteer period. 
                  I authorize without reservation, any person, business or agency contacted by the consumer reporting agency to furnish 
                  the above-mentioned information.                  
                </div>
                <div v-else>
                  Por la presente autorizo la adquisición del informe(s) del consumidor e informe(s) de investigación del consumidor enumerados 
                  en la Divulgación por <u> Labatt Food Service </u> (""La Compañía"") y a su agencia de investigación Integrated Screening Partners. 
                  Si llegase a ser contratado(a), esta autorización permanecerá archivada y servirá como autorización continua para que La Compañía 
                  obtenga dichos informes en cualquier momento durante el período de mi empleo, contrato o tiempo voluntario, mientras sea permitido 
                  por la ley. Autorizo sin reserva a cualquier persona, negocio o agencia contratada por la agencia de investigación a proporcionar 
                  la información mencionada anteriormente.
                </div>
                <div 
                  v-if="!readMoreConsumerReports1"
                  class="d-flex justify-center read-more-toggle mt-3 mb-6"
                  @click="readMoreConsumerReports1 = true"
                > 
                  <span><i> {{ isEnglish ? 'Read more' : 'Leer más' }} </i></span>
                  <v-icon
                    class="ml-1"
                    icon="mdi-chevron-down"
                    :title="isEnglish ? 'Read more' : 'Leer más'"
                  ></v-icon>
                </div>
                <div v-if="readMoreConsumerReports1">
                  <br>
                  <div v-if="isEnglish">
                    I understand that I have rights under the Fair Credit Reporting Act, and I acknowledge receipt of the Summary of Rights.                  
                  </div>
                  <div v-else>
                    Entiendo que tengo derechos bajo la Ley de Informes de Crédito Justos y acuso de recibo del Resumen de Derechos.
                  </div> 
                  <br>
                  <div v-if="isEnglish">
                    I authorize Company and Agency to use email communication with me to provide me with notices and information regarding 
                    any report or use of such report. If I do not have an email address or do not wish to share it, then communication will 
                    be by U.S. Mail, which will result in slower communication. If you have any questions concerning this background screening 
                    content, please contact: Integrated Screening Partners at (800) 474-4420.
                  </div> 
                  <div v-else>
                    Autorizo a La Compañía y a La Agencia a utilizar correo electrónico para comunicarse conmigo y para que me envíen 
                    notificaciones e información relacionada a cualquier reporte o uso de los mismos. Si no tengo correo electrónico o 
                    no deseo compartirlo, la comunicación será a través del Correo Postal de los Estados Unidos, el cual resultará una 
                    comunicación más lenta. Si tiene alguna pregunta en relación al contenido de esta investigación de antecedentes, 
                    favor de contactar a Integrated Screening Partners al (800) 474-4420.
                  </div>              
                </div> 
                <div 
                  v-if="readMoreConsumerReports1"      
                  class="d-flex justify-center read-more-toggle mt-3 mb-6"            
                  @click="readMoreConsumerReports1 = false"
                > 
                  <v-icon
                    icon="mdi-chevron-up"
                    :title="isEnglish ? 'Hide' : 'Esconder'"
                  ></v-icon> 
                </div>
                <div>
                  <!-- Today's Date -->
                  <v-text-field
                    v-model="app.consumerReportFormDate"
                    :rules="required"
                    :readonly="(loading || app.isConsumerReportFormSigned)"
                    type="date"
                    class="tf-padding"
                    :label="isEnglish ? 'Today\'s date' : 'Fecha'"
                    variant="outlined"
                    hide-details
                  ></v-text-field>
                  <!-- Full Name -->
                  <v-text-field
                    v-model="app.consumerReportFormFullName"
                    :rules="required"
                    :readonly="(loading || app.isConsumerReportFormSigned)"
                    class="tf-padding"
                    :label="isEnglish ? 'Full name' : 'Nombre completo'"
                    variant="outlined"
                    hide-details
                  ></v-text-field>
                  <!-- Signature -->
                  <div class="d-flex justify-center"> 
                    {{ isEnglish ? 'Please type your first and last name' : 'Por favor escriba su nombre y apellido' }}
                  </div>
                  <v-text-field
                    v-model="app.consumerReportFormSignature1"
                    :rules="required"
                    :readonly="(loading || app.isConsumerReportFormSigned)"
                    class="tf-padding input-signature"
                    variant="outlined"
                    hide-details
                  ></v-text-field>
                  <div class="d-flex justify-center"> {{ isEnglish ? 'Signature' : 'Firma' }} </div>
                </div>
                <br>
                <div>
                  {{ isEnglish ? 'For identification purposes:' : 'Por fines de identificación: ' }} <br>
                  {{ isEnglish ? 'Social Security Number: ' : 'Número de Seguro Social: ' }} <u> {{ formatSSN(personalInformation.socialSecurityNumber) }}</u><br>
                  {{ isEnglish ? 'Date of Birth: ' : 'Fecha de Nacimiento: ' }} <u>{{ formatDate(personalInformation.dateOfBirth) }}</u><br>
                  {{ isEnglish ? 'Street Address: ' : 'Dirección: ' }} <u> {{ personalInformation.primaryAddress1 }}</u><br>
                  {{ isEnglish ? 'City: ' : 'Ciudad: ' }} <u>{{ personalInformation.primaryAddressCity }}</u><br>
                  {{ isEnglish ? 'State: ' : 'Estado: ' }} <u>{{ personalInformation.primaryAddressState }}</u><br>
                  {{ isEnglish ? 'Zip Code: ' : 'Código Postal: ' }} <u>{{ personalInformation.primaryAddressZipCode }}</u><br>
                  {{ isEnglish ? 'Driver\'s License Number: ' : 'Número de Licencia de Conducir: ' }} <u>{{ personalInformation.driverLicenseNumber }}</u><br>
                  {{ isEnglish ? 'State of Issuance: ' : 'Estado de Emisión: ' }} <u>{{ personalInformation.driverLicenseState }}</u><br>
                  {{ isEnglish ? 'Expiration: ' : 'Expiración: ' }} <u>{{ formatDate(personalInformation.driverLicenseExpirationDate) }}</u><br>
                </div>
                <br>
                <hr>
                <br>
                <div><b> {{ isEnglish ? 'INFORMATION REGARDING YOUR RIGHTS' : 'INFORMACIÓN RELACIONADA A SUS DERECHOS' }} </b></div> 
                <br>
                <div v-if="isEnglish">
                  I acknowledge that I have received and carefully read and understand the separate "Disclosure", the separate "Authorization", 
                  and the separate "Summary of Rights under the Fair Credit Reporting Act" that have been provided to me by the Company. I also 
                  acknowledge receipt of and that I have carefully read and understand (as applicable), the separate California Disclosure, and 
                  the separate New York Article 23-A that have been provided to me.
                </div>
                <div v-else>
                  Reconozco que he recibido y leído cuidadosamente y también entendido las formas  
                  de la "Divulgación", "Autorización" y "Resumen de derechos bajo la Ley de Reportes de Crédito" que me 
                  ha sido proporcionada por la compañía. También reconozco haber recibido y que he cuidadosamente leído 
                  y entendido (según se aplica), la separada de Divulgación de California y la de Nueva York artículo 23-A 
                  el cual se me ha dado.
                </div>
                <div 
                  v-if="!readMoreConsumerReports2"
                  class="d-flex justify-center read-more-toggle mt-3 mb-6"
                  @click="readMoreConsumerReports2 = true"
                > 
                  <span><i> {{ isEnglish ? 'Read more' : 'Leer más' }} </i></span>
                  <v-icon
                    class="ml-1"
                    icon="mdi-chevron-down"
                    :title="isEnglish ? 'Read more' : 'Leer más'"
                  ></v-icon>
                </div>
                <div v-if="readMoreConsumerReports2">
                  <br>
                  <div v-if="isEnglish">
                    I understand that I have the right to make a request to the consumer reporting agency: Integrated Screening Partners ("Agency"), 
                    5316 Hwy. 290 West, Suite 500, Austin, TX 78735, telephone number (800) 474-4420, upon proper identification, to obtain copies 
                    of any reports furnished to Company by the Agency and to request the nature and substance of all information in its files on me 
                    at the time of my request, including the sources of information. The Agency will also disclose the recipients of any such reports 
                    on me which the Agency has previously furnished within the two year period for employment requests, and one year for other purposes 
                    preceding my request (California three years). I understand that I can dispute, at any time, any information that is inaccurate in 
                    any type of report with the Agency. I may view the Agency's privacy policy at their website: 
                    <u> www.integratedscreening.com/privacy.asp </u>
                  </div>
                  <div v-else>
                    Entiendo que tengo el derecho de hacer una solicitud a la agencia de informes del 
                    consumidor; Integrated Screening Partners (Agencia) 5316 Hwy. 290 West, Suite 500, Austin, TX 78735, 
                    número de teléfono (800) 474-4420, presentando identificación adecuada, para obtener copias de cualquier 
                    informe proporcionado a la compañía por la agencia y para solicitar la naturaleza y contenido de toda la 
                    información en sus archivos relacionadas a mi persona al momento de la solicitud, incluyendo las fuentes 
                    de información consultadas. La agencia también divulgará a los destinatarios de dichos informes que la misma 
                    ha proporcionado previamente dentro del período de dos años para solicitudes de empleo y un año para otros 
                    fines previos a mi solicitud. (Para California aplica tres años). Entiendo que puedo alegar con la Agencia en 
                    cualquier momento cualquier información que sea inexacta en cualquier tipo de reporte.
                  </div>
                  <br>
                  <div v-if="isEnglish">
                    If I am hired, I understand that my employer can use this disclosure and authorization to continue to obtain such consumer reports 
                    throughout my employment, contract period or volunteer service.
                  </div>
                  <div v-else>
                    Si soy contratado, entiendo que mi empleador puede ocupar esta divulgación y 
                    autorización para continuar recibiendo los reportes en a lo largo de mi período de empleo, contrato laboral o 
                    servicio voluntario.
                  </div>
                  <br>
                  <div v-if="isEnglish">
                    I understand that certain states such as California, Colorado, Vermont, etc., require a separate disclosure and/or authorization 
                    to obtain a consumer's credit report for employment purposes. If such documentation is required, Company will provide this to me 
                    before ordering the credit report.
                  </div>
                  <div v-else>
                    Entiendo que en algunos estados, como California, Colorado, Vermont, Etc. se requiere 
                    de una separata distinta y por separado de la divulgación y autorización para obtener los informes de crédito 
                    del consumidor para fines laborales. Si esta documentación es requerida, la Compañía deberá de proveerme 
                    antes de solicitarlos.
                  </div>
                  <br>
                  <div v-if="isEnglish">
                    I understand that if the Company is located in California, Minnesota or Oklahoma, that I have the right to request a copy of any 
                    report Company receives on me at the time the report is provided to Company. By checking the following box, I request a copy of 
                    all such reports be sent to me.
                  </div>
                  <div v-else>
                    Entiendo que si la Compañía está localizada en California, Minnesota y Oklahoma, tengo 
                    el derecho de solicitar una copia de cualquier reporte que contenga información mía y que reciba la compañía 
                    al momento que la misma la reciba. Al seleccionar el siguiente ítem, solicito una copia de dichos reportes para 
                    que me sean enviados.
                  </div>
                  <br>
                  <div class="d-flex">            
                    <div class="mt-4"> 
                      <b> {{ isEnglish ? 'Check here: ' : 'Seleccionar aquí: ' }} </b>
                    </div>
                    <v-checkbox 
                      v-model="app.consumerReportFormRequestCopyOfReports"
                      :readonly="(loading || app.isConsumerReportFormSigned)"
                      class="ml-2"
                      hide-details
                    ></v-checkbox>
                  </div>  
                  <br>
                  <div v-if="isEnglish">
                    I understand that if the report is provided to an employer in the State of Washington, that I can contact the following office for 
                    more information regarding my rights under Washington state law in regard to these reports: State of Washington Attorney General, 
                    Consumer Protection Division, 800 5th Ave, Ste. 2000, Seattle, Washington 98104-3188, (206) 464-7744.
                  </div>
                  <div v-else>
                    Entiendo que si el informe es entregado a un empleador en el estado 
                    de Washington, puedo contactar a la siguiente oficina para más información relacionada a mis derechos bajo 
                    la ley del estado de Washington en relación a estos reportes: State of Washington Attorney General, Consumer 
                    Protection Division, 800 5th Ave. Ste: 2000, Seattle, Washington 98104-3188, (206) 464-7744.
                  </div>
                  <br>
                  <div v-if="isEnglish">
                    New Hampshire registered drivers: The consent for driving records is valid for only two (2) years and is revocable at any time.
                  </div>
                  <div v-else>
                    Conductores registrados de New Hampshire: El consentimiento para registros de manejo es válido sólo por 2 años y es revocable en 
                    cualquier momento.
                  </div>
                  <br>
                  <div v-if="isEnglish">
                    Personal information in driving records means information that identifies you, such as your photograph, social security number, 
                    driver's license number, your name, your address, your telephone number and medical or disability information relating to any 
                    license restrictions. Highly restricted personal information includes your photograph or image, social security number, medical 
                    or disability information relating to any license restrictions. 18 U.S.C. §2725.
                  </div>
                  <div v-else>
                    Información personal en registros de manejo significa información que lo identifique,
                    así como una fotografía, número de seguro social, número de licencia de conducir, nombre completo, dirección 
                    de residencia, número de teléfono y/o información médica o de discapacidad relacionada a alguna restricción 
                    de la licencia. La información altamente restringida incluye su fotografía o imagen, número de seguro social, y/o 
                    información médica o de discapacidad relacionada a alguna restricción de la licencia. 18 U.S.C. §2725.
                  </div>                
                </div> 
                <div 
                  v-if="readMoreConsumerReports2"      
                  class="d-flex justify-center read-more-toggle mt-3 mb-6"            
                  @click="readMoreConsumerReports2 = false"
                > 
                  <v-icon
                    icon="mdi-chevron-up"
                    :title="isEnglish ? 'Hide' : 'Esconder'"
                  ></v-icon> 
                </div>              
                <div>
                  <!-- Today's Date -->
                  <v-text-field
                    v-model="app.consumerReportFormDate"
                    :rules="required"
                    :readonly="(loading || app.isConsumerReportFormSigned)"
                    type="date"
                    class="tf-padding"
                    :label="isEnglish ? 'Today\'s Date' : 'Fecha'"
                    variant="outlined"
                    hide-details
                  ></v-text-field>
                  <!-- Full Name -->
                  <v-text-field
                    v-model="app.consumerReportFormFullName"
                    :rules="required"
                    :readonly="(loading || app.isConsumerReportFormSigned)"
                    class="tf-padding"
                    :label="isEnglish ? 'Full Name' : 'Nombre completo'"
                    variant="outlined"
                    hide-details
                  ></v-text-field>
                  <!-- Signature -->
                  <div class="d-flex justify-center"> 
                    {{ isEnglish ? 'Please type your first and last name' : 'Por favor escriba su nombre y apellido' }}
                  </div>
                  <v-text-field
                    v-model="app.consumerReportFormSignature2"
                    :rules="required"
                    :readonly="(loading || app.isConsumerReportFormSigned)"
                    class="tf-padding input-signature"
                    variant="outlined"
                    hide-details
                  ></v-text-field> 
                  <div class="d-flex justify-center"> {{ isEnglish ? 'Signature' : 'Firma' }} </div>
                </div>
                <br>
                <hr>
                <br>
                <div><b> {{ isEnglish ? 'DISCLOSURE FOR CONSUMER REPORTS' : 'DIVULGACIÓN PARA REPORTES DE CONSUMO' }} </b></div>
                <br>
                <div v-if="isEnglish">
                  <u> Labatt Food Service </u> ("Company") may obtain one or more consumer reports and/or investigative consumer reports that contains 
                  background information about you in connection with your employment (including contract or volunteer services). The reports may 
                  include information about your character, general reputation, personal characteristics or mode of living.
                </div>
                <div v-else>
                  <u> Labatt Food Service </u> (""La Compañía"") puede obtener uno o más reportes de consumo y/o de investigación que contenga
                  información de sus antecedentes en relación a su empleo (incluyendo contrato o voluntariado). Estos 
                  reportes pueden incluir información personal, reputación general, características personales o estilo 
                  de vida.
                </div>
                <div 
                  v-if="!readMoreConsumerReports3"
                  class="d-flex justify-center read-more-toggle mt-3 mb-6"
                  @click="readMoreConsumerReports3 = true"
                > 
                  <span><i> {{ isEnglish ? 'Read more' : 'Leer más' }} </i></span>
                  <v-icon
                    class="ml-1"
                    icon="mdi-chevron-down"
                    :title="isEnglish ? 'Read more' : 'Leer más'"
                  ></v-icon>
                </div>
                <div v-if="readMoreConsumerReports3">
                  <br>
                  <div v-if="isEnglish">
                    These reports as allowed by law, may include but are not limited to, the following types of information, as applicable: criminal and 
                    other public records and history, public court records, motor vehicle and driving records, identity verification, educational and 
                    employment history, drug/alcohol test results, professional credentials, licensure, credit history, and other information with public 
                    or private information sources.
                  </div>
                  <div v-else>
                    Estos reportes, permitidos por la ley, pueden incluir (pero no están limitados)
                    el siguiente tipo de información, si es aplicable: Registros criminales y otros registros públicos y su historial,
                    registros públicos de la corte, registros de vehículo de motor y de manejo, verificación de identidad, historial
                    de estudio y empleo, resultados de exámenes toxicológicos (droga/alcohol), credenciales profesionales,
                    licencias, historial crediticio y otra información con fuentes públicas o privadas.
                  </div>
                  <br>
                  <div v-if="isEnglish">
                    If investigative consumer reports are obtained, information additionally will include employment or personal references.
                  </div>  
                  <div v-else>
                    Si los informes de investigación de los consumidores son obtenidos, información 
                    adicional puede incluir referencias laborales o personales.
                  </div>          
                </div> 
                <div 
                  v-if="readMoreConsumerReports3"      
                  class="d-flex justify-center read-more-toggle mt-3 mb-6"            
                  @click="readMoreConsumerReports3 = false"
                > 
                  <v-icon
                    icon="mdi-chevron-up"
                    :title="isEnglish ? 'Hide' : 'Esconder'"
                  ></v-icon> 
                </div>
                <div>
                  <!-- Today's Date -->
                  <v-text-field
                    v-model="app.consumerReportFormDate"
                    :rules="required"
                    :readonly="(loading || app.isConsumerReportFormSigned)"
                    type="date"
                    class="tf-padding"
                    :label="isEnglish ? 'Today\'s Date' : 'Fecha'"
                    variant="outlined"
                    hide-details
                  ></v-text-field>
                  <!-- Full Name -->
                  <v-text-field
                    v-model="app.consumerReportFormFullName"
                    :rules="required"
                    :readonly="(loading || app.isConsumerReportFormSigned)"
                    class="tf-padding"
                    :label="isEnglish ? 'Full Name' : 'Nombre completo'"
                    variant="outlined"
                    hide-details
                  ></v-text-field>
                  <!-- Signature -->
                  <div class="d-flex justify-center"> 
                    {{ isEnglish ? 'Please type your first and last name' : 'Por favor escriba su nombre y apellido' }}
                  </div>
                  <v-text-field
                    v-model="app.consumerReportFormSignature3"
                    :rules="required"
                    :readonly="(loading || app.isConsumerReportFormSigned)"
                    class="tf-padding input-signature"
                    variant="outlined"
                    hide-details
                  ></v-text-field> 
                  <div class="d-flex justify-center"> {{ isEnglish ? 'Signature' : 'Firma' }} </div>
                </div>
                <br>
                <!-- Sign - Btn -->
                <div class="d-flex justify-center">
                  <v-btn 
                    :disabled="app.isConsumerReportFormSigned ? true : false"
                    class="btn orange-btn" 
                    elevation="0"
                    type="submit"
                    @click="signConsumerReportForm()"
                  >
                    <span v-if="isEnglish">
                      {{ app.isConsumerReportFormSigned ? 'Signed' : 'Sign' }}
                    </span>
                    <span v-else>
                      {{ app.isConsumerReportFormSigned ? 'Firmado' : 'Firmar' }}
                    </span>
                  </v-btn>
                </div>
              </v-expansion-panel-text>
            </v-expansion-panel>
            <!-- Summary of Rights -->
            <v-expansion-panel>
              <v-expansion-panel-title> 
                <v-icon
                  v-if="app.isSummaryOfRightsFormSigned"
                  class="mr-3 success-color"
                  icon="mdi-check-bold"
                  :title="isEnglish ? 'Signed' : 'Firmado'"
                ></v-icon>
                {{ isEnglish ? 'Summary of Rights under FCRA' : 'Resumen de sus Derechos en Virtud de la Ley de Informe Justo de Crédito' }}
              </v-expansion-panel-title>
              <v-expansion-panel-text class="font-sm">
                <br>
                <div v-if="isEnglish">
                  Please read the following form. If you are applying in our office and would like a printed copy please ask the hiring assistant.
                </div>
                <div v-else>
                  Por favor, lea el siguiente formulario. Si usted está aplicando en nuestra oficina y desea una copia impresa por favor 
                  preguntele al asistente.
                </div>
                <br>
                <div> 
                  Para información en español, visite <u>www.consumerfinance.gov/learnmore</u> o escribe al Consumer Financial Protection Bureau, 
                  1700 G Street N.W., Washington, DC 20552. 
                </div>
                <br>
                <div v-if="isEnglish">
                  <b> A Summary of Your Rights Under the Fair Credit Reporting Act </b>
                </div>
                <div v-else>
                  <b> Un resumen de sus derechos en virtud de la Ley de Informe Justo de Crédito </b>
                </div>
                <br>
                <div v-if="isEnglish">
                  The federal Fair Credit Reporting Act (FCRA) promotes the accuracy, fairness, and privacy of information in the files of consumer 
                  reporting agencies. There are many types of consumer reporting agencies, including credit bureaus and specialty agencies 
                  (such as agencies that sell information about check writing histories, medical records, and rental history records). 
                  Here is a summary of your major rights under the FCRA. For more information, including information about additional rights, 
                  go to <u>www.consumerfinance.gov/learnmore</u> or write to: Consumer Financial Protection Bureau, 1700 G Street N.W., Washington, DC 20552.
                </div>
                <div v-else>
                  La Ley de Informe Justo de Crédito (Fair Credit Reporting Act, FCRA), una ley federal, fomenta 
                  la exactitud, imparcialidad y privacidad de la información en los archivos de las agencias de informe 
                  del consumidor. Existen muchos tipos de agencias de informe del consumidor, incluidas las agencias 
                  de crédito (credit bureaus) y las agencias especializadas (como las agencias que venden información 
                  sobre el historial de extensión de cheques, registros médicos y registros de historial de alquiler). 
                  A continuación se presenta un resumen de sus principales derechos en virtud de la FCRA. Para
                  obtener más información, incluyendo información sobre derechos adicionales, visite
                  <u>www.consumerfinance.gov/learnmore</u> o escriba a: Consumer Financial Protection Bureau,
                  1700 G Street N.W., Washington, DC 20552.
                </div>
                <div 
                  v-if="!readMoreSummaryOfRights"
                  class="d-flex justify-center read-more-toggle mt-3 mb-6"
                  @click="readMoreSummaryOfRights = true"
                > 
                  <span><i> {{ isEnglish ? 'Read more' : 'Leer más' }} </i></span>
                  <v-icon
                    class="ml-1"
                    icon="mdi-chevron-down"
                    :title="isEnglish ? 'Read more' : 'Leer más'"
                  ></v-icon>
                </div>
                <div v-if="readMoreSummaryOfRights">
                  <br>
                  <div v-if="isEnglish">
                    You must be told if information in your file has been used against you. Anyone who uses a credit report or another type of consumer 
                    report to deny your application for credit, insurance, or employment – or to take another adverse action against you – must tell you, 
                    and must give you the name, address, and phone number of the agency that provided the information.
                  </div>
                  <div v-else>
                    Deben notificarle si la información contenida en su archivo se ha utilizado en su contra.
                    Todo aquel que utilice un informe de crédito u otro tipo de informe de consumidor para denegar su solicitud de crédito, 
                    seguro o empleo, o para emprender otra acción adversa en su contra, debe informarle y debe darle el nombre, la dirección
                    y el número de teléfono de la agencia que proporcionó esa información.
                  </div>
                  <br>
                  <div v-if="isEnglish">
                    You have the right to know what is in your file. You may request and obtain all the information about you in the files of a consumer 
                    reporting agency (your "file disclosure"). You will be required to provide proper identification, which may include your Social Security 
                    number. In many cases, the disclosure will be free. You are entitled to a free file disclosure if: <br>
                    <br>
                    <ul class="ml-6">
                      <li> a person has taken adverse action against you because of information in your credit report; </li>
                      <li> you are the victim of identity theft and place a fraud alert in your file; </li>
                      <li> your file contains inaccurate information as a result of fraud; </li>
                      <li> you are on public assistance; </li>
                      <li> you are unemployed but expect to apply for employment within 60 days. </li>
                    </ul>
                  </div>
                  <div v-else>
                    Usted tiene derecho a saber lo que contiene su archivo. Usted puede 
                    solicitar y obtener toda la información registrada bajo su nombre en los archivos de una agencia
                    de informe del consumidor (divulgación de su información). Usted deberá proporcionar una prueba
                    de su identidad, que puede incluir su número de Seguro Social. En muchos casos, la divulgación 
                    de la información de su archivo será gratuita. Usted tiene derecho 
                    a recibir una copia gratuita de su archivo si: <br>
                    <br>
                    <ul class="ml-6">
                      <li> Una persona ha emprendido una acción adversa en su contra debido a la información contenida en su informe de crédito. </li>
                      <li> Usted es víctima de un robo de identidad y coloca una alerta de fraude en su archivo. </li>
                      <li> Su archivo contiene información inexacta como resultado de fraude. </li>
                      <li> Usted recibe asistencia pública. </li>
                      <li> Usted no está empleado pero prevé solicitar empleo en un plazo de 60 días. </li>
                    </ul>
                  </div>                  
                  <br>
                  <div v-if="isEnglish">
                    In addition, all consumers are entitled to one free disclosure every 12 months upon request from each nationwide credit bureau and 
                    from nationwide specialty consumer reporting agencies. See <u>www.consumerfinance.gov/learnmore</u> for additional information.
                  </div>
                  <div v-else>
                    Asimismo, todos los consumidores tendrán derecho a recibir una copia gratuita de la 
                    información registrada en su archivo cada 12 meses si así se lo solicitan a cada agencia de 
                    crédito a nivel nacional y a las agencias especializadas de informe del consumidor a nivel 
                    nacional. Para obtener más información, visite <u>www.consumerfinance.gov/learnmore</u>.
                  </div>
                  <br>
                  <div v-if="isEnglish">
                    You have the right to ask for a credit score. Credit scores are numerical summaries of your credit-worthiness based on information from 
                    credit bureaus. You may request a credit score from consumer reporting agencies that create scores or distribute scores used in residential 
                    real property loans, but you will have to pay for it. In some mortgage transactions, you will receive credit score information for free 
                    from the mortgage lender.
                  </div>
                  <div v-else>
                    Usted tiene derecho a pedir su puntaje de crédito. Los puntajes de crédito son resúmenes numéricos de su solvencia de crédito basados 
                    en la información de las agencias de crédito. Usted puede solicitar su puntaje de crédito a las agencias de informe del consumidor 
                    que generan o distribuyen los puntajes utilizados en préstamos de bienes raíces residenciales, pero 
                    tendrá que pagar un cargo. En algunas transacciones hipotecarias, el prestamista le dará información 
                    sobre su puntaje de crédito gratuitamente.
                  </div>
                  <br>
                  <div v-if="isEnglish">
                    You have the right to dispute incomplete or inaccurate information. If you identify information in your file that is incomplete or inaccurate, 
                    and report it to the consumer reporting agency, the agency must investigate unless your dispute is frivolous. See 
                    <u>www.consumerfinance.gov/learnmore</u> for an explanation of dispute procedures.
                  </div>
                  <div v-else>
                    Usted tiene derecho a impugnar la información incompleta o inexacta. Si 
                    usted identifica información en su archivo que es incompleta o inexacta, y la reporta a la agencia 
                    de informe del consumidor, la agencia debe investigar, a menos que su impugnación sea frívola. Para 
                    consultar una explicación sobre los procedimientos de impugnación, visite 
                    <u>www.consumerfinance.gov/learnmore</u>.
                  </div>
                  <br>
                  <div v-if="isEnglish">
                    Consumer reporting agencies must correct or delete inaccurate, incomplete, or unverifiable information. Inaccurate, incomplete, or unverifiable 
                    information must be removed or corrected, usually within 30 days. However, a consumer reporting agency may continue to report information it 
                    has verified as accurate.
                  </div>
                  <div v-else>
                    Las agencias de informe del consumidor deben corregir o eliminar la información inexacta, incompleta o no verificable.
                    La información inexacta, incompleta o no verificable debe ser eliminada o corregida, por lo general en un plazo de 30 días. No obstante, 
                    si una agencia de informe del consumidor verifica la exactitud de la información, puede seguir reportándola.
                  </div>
                  <br>
                  <div v-if="isEnglish">
                    Consumer reporting agencies may not report outdated negative information. In most cases, a consumer reporting agency may not report negative 
                    information that is more than seven years old, or bankruptcies that are more than 10 years old.
                  </div>
                  <div v-else>
                    Las agencias de informe del consumidor no pueden reportar información negativa desactualizada. 
                    En la mayoría de los casos, una agencia de informe del consumidor no 
                    puede reportar información negativa ocurrida hace más de siete años,
                    ni quiebras ocurridas hace más de 10 años.
                  </div>
                  <br>
                  <div v-if="isEnglish">
                    Access to your file is limited. A consumer reporting agency may provide information about you only to people with a valid need - usually to 
                    consider an application with a creditor, insurer, employer, landlord, or other business. The FCRA specifies those with a valid need for access.
                  </div>
                  <div v-else>
                    El acceso a su archivo es limitado. Una agencia de informe del consumidor 
                    puede proporcionar información sobre usted solamente a aquellas personas que realmente la 
                    necesiten — generalmente para considerar una solicitud presentada por usted ante un acreedor, 
                    asegurador, empleador, propietario de una vivienda en alquiler u otro negocio. La FCRA especifica 
                    quiénes son las personas que tienen una necesidad válida de acceso.
                  </div>
                  <br>
                  <div v-if="isEnglish">
                    You must give your consent for reports to be provided to employers. A consumer reporting agency may not give out information about you to your 
                    employer, or a potential employer, without your written consent given to the employer. Written consent generally is not required in the trucking 
                    industry. For more information, go to <u>www.consumerfinance.gov/learnmore</u>.
                  </div>
                  <div v-else>
                    Usted debe otorgar su consentimiento para que se envíen sus informes a los empleadores. 
                    Una agencia de informe del consumidor no puede darle información sobre 
                    usted a su empleador, ni a un posible empleador sin su consentimiento escrito a nombre del 
                    empleador. Por lo general, el consentimiento escrito no es requerido en la industria del 
                    transporte de carga por camión. Para obtener más información, visite <u>www.consumerfinance.gov/learnmore</u>.
                  </div>
                  <br>
                  <div v-if="isEnglish">
                    You may limit "prescreened" offers of credit and insurance you get based on information in your credit report. Unsolicited "prescreened" offers 
                    for credit and insurance must include a toll-free phone number you can call if you choose to remove your name and address from the lists 
                    these offers are based on. You may opt out with the nationwide credit bureaus at 1-888-5-OPTOUT (1-888-567-8688).
                  </div>
                  <div v-else>
                    Usted puede limitar las ofertas "pre-evaluadas" de crédito y seguro que recibe 
                    y que están basadas en la información de su informe de crédito. Las ofertas 
                    "pre-evaluadas" de crédito y seguro no solicitadas deben incluir un número de teléfono 
                    gratuito al que usted puede llamar si desea eliminar su nombre y dirección de las listas en 
                    las que se basan estas ofertas. Puede solicitar su exclusión voluntaria de estas listas llamando 
                    a las agencias de crédito a nivel nacional al 1-888-5-OPTOUT (1-888-567-8688). 
                  </div>
                  <br>
                  <div v-if="isEnglish">
                    The following FCRA right applies with respect to nationwide consumer reporting agencies:
                  </div>
                  <div v-else>
                    El siguiente derecho, en virtud de la FCRA, se aplica a las agencias de informe del consumidor a nivel nacional: 
                  </div>
                  <br>
                  <div v-if="isEnglish"><b> CONSUMERS HAVE THE RIGHT TO OBTAIN A SECURITY FREEZE </b></div>
                  <div v-else><b> LOS CONSUMIDORES TIENEN EL DERECHO A OBTENER UNA SUSPENSIÓN POR SEGURIDAD </b></div>
                  <br>
                  <div v-if="isEnglish">
                    You have a right to place a "security freeze" on your credit report, which will prohibit a consumer reporting agency from releasing information 
                    in your credit report without your express authorization. The security freeze is designed to prevent credit, loans, and services from being 
                    approved in your name without your consent. However, you should be aware that using a security freeze to take control over who gets access to 
                    the personal and financial information in your credit report may delay, interfere with, or prohibit the timely approval of any subsequent request 
                    or application you make regarding a new loan, credit, mortgage, or any other account involving the extension of credit.
                  </div>
                  <div v-else>
                    Usted tiene derecho a colocar un "congelamiento de seguridad" en su informe de crédito, la misma que 
                    prohíbe a las agencias de informe del consumidor, a entregar información sobre su informe de crédito 
                    sin su autorización expresa. El congelamiento de seguridad está diseñado para evitar que créditos, 
                    préstamos y servicios se aprueben en su nombre sin su consentimiento. Sin embargo, usted debe saber 
                    que colocar un congelamiento de seguridad para controlar el acceso a la información personal y 
                    financiera en su informe de crédito podría retrasar, interferir o bloquear la aprobación a tiempo de 
                    peticiones o solicitudes posteriores que usted haga con respecto a un nuevo préstamo, crédito, 
                    hipoteca o cualquier otra transacción para obtener un crédito.
                  </div>
                  <br>
                  <div v-if="isEnglish">
                    As an alternative to a security freeze, you have the right to place an initial or extended fraud alert on your credit file at no cost. An initial 
                    fraud alert is a 1-year alert that is placed on a consumer's credit file. Upon seeing a fraud alert display on a consumer's credit file, a business 
                    is required to take steps to verify the consumer's identity before extending new credit. If you are a victim of identity theft, you are entitled to 
                    an extended fraud alert, which is a fraud alert lasting 7 years.
                  </div>
                  <div v-else>
                    Como alternativa a un congelamiento de seguridad, usted tiene derecho a colocar una alerta de fraude inicial o extendida en 
                    su archivo de crédito sin costo alguno. Una alerta de fraude inicial es un aviso que se coloca en el 
                    archivo de crédito del consumidor por un (1) año. Cuando una alerta de fraude se despliega en el 
                    archivo de crédito del consumidor, la empresa está obligada a tomar medidas para verificar la 
                    identidad de dicho consumidor, antes de concederle un crédito. Si usted es una víctima del robo de 
                    identidad, usted tiene derecho a colocar una alerta de fraude extendida, que es un aviso de fraude 
                    que dura 7 años.
                  </div>
                  <br>
                  <div v-if="isEnglish">
                    A security freeze does not apply to a person or entity, or its affiliates, or collection agencies acting on behalf of the person or entity, with 
                    which you have an existing account that requests information in your credit report for the purposes of reviewing or collecting the account. Reviewing 
                    the account includes activities related to account maintenance, monitoring, credit line increases, and account upgrades and enhancements.
                  </div>
                  <div v-else>
                    El congelamiento de seguridad no es aplicable a personas o entidades, 
                    ni a las subsidiarias o agencias de cobranza que actúen en nombre de dichas personas o entidades, 
                    con las cuales usted ya tiene una cuenta y que solicitan información sobre su informe de crédito con 
                    el fin de cobrarle o revisar su cuenta. Revisar una cuenta significa realizar ciertas actividades 
                    como el mantenimiento, vigilancia, actualizaciones, mejoras y aumentos a la línea de crédito de 
                    dicha cuenta.
                  </div>
                  <br>
                  <div v-if="isEnglish">
                    You may seek damages from violators. If a consumer reporting agency, or, in some cases, a user of consumer reports or a furnisher of information to a 
                    consumer reporting agency violates the FCRA, you may be able to sue in state or federal court.
                  </div>
                  <div v-else>
                    Usted puede obtener compensación de los infractores. Si una agencia de 
                    informe del consumidor o, en algunos casos, un usuario de informe del consumidor, o un proveedor 
                    de información de una agencia de informe del consumidor infringe la FCRA, usted puede demandarlo 
                    ante una corte estatal o federal.
                  </div>
                  <br>
                  <div v-if="isEnglish">
                    Identity theft victims and active duty military personnel have additional rights. For more information, visit <u>www.consumerfinance.gov/learnmore</u>.
                  </div>
                  <div v-else>
                    Las víctimas del robo de identidad y el personal militar en servicio activo tienen 
                    derechos adicionales. Para obtener más información, visite <u>www.consumerfinance.gov/learnmore</u>.
                  </div>
                  <br>
                  <div v-if="isEnglish">
                    States may enforce the FCRA, and many states have their own consumer reporting laws. In some cases, you may have more rights under state law. For more 
                    information, contact your state or local consumer protection agency or your state Attorney General. For information about your federal rights, contact:
                  </div>
                  <div v-else>
                    Los estados tienen autoridad para hacer cumplir la FCRA, y muchos estados tienen su 
                    propia legislación sobre los informes de los consumidores. En algunos casos, usted puede tener 
                    más derechos en virtud de la ley estatal. Para obtener más información, comuníquese con su 
                    agencia estatal o local de protección del consumidor o con el Fiscal General estatal. Para 
                    obtener información sobre sus derechos federales, establezca contacto con:
                  </div>
                  <br>
                  <div>
                    <b> {{ isEnglish ? 'TYPE OF BUSINESS & CONTACT:' : 'TIPO DE NEGOCIO:' }} </b>
                  </div>
                  <br>
                  <div v-if="isEnglish">
                    1. <br>
                    <br>
                    a. Banks, savings associations, and credit unions with total assets of over $10 billion and their affiliates <br>
                    <br>
                    Consumer Financial Protection Bureau <br>
                    1700 G. Street N.W. <br>
                    Washington, DC 20552 <br>
                    <br>
                    b. Such affiliates that are not banks, savings associations, or credit unions also should list, in addition to the CFPB: <br>
                    <br>
                    Federal Trade Commission <br>
                    Consumer Response Center <br>
                    600 Pennsylvania Avenue, N.W. <br>
                    Washington, DC 20580 <br>
                    (877) 382-4357 <br>
                  </div>
                  <div v-else>
                    1. <br>
                    <br>
                    a. Bancos, asociaciones de ahorro y cooperativas de crédito con activos totales de más de $10 mil millones de dólares y sus filiales <br>
                    <br>
                    Consumer Financial Protection Bureau <br>
                    1700 G. Street N.W. <br>
                    Washington, DC 20552 <br>
                    <br>
                    b. Dichas filiales que no sean bancos, asociaciones de ahorro o cooperativas de crédito también deben listar, además del CFPB: <br>
                    <br>
                    Federal Trade Commission <br>
                    Consumer Response Center <br>
                    600 Pennsylvania Avenue, N.W. <br>
                    Washington, DC 20580 <br>
                    (877) 382-4357 <br>
                  </div>
                  <br>
                  <div v-if="isEnglish">
                    2. To the extent not included in item 1 above: <br>
                    <br>
                    a. National banks, federal savings associations, and federal branches and federal agencies of foreign banks <br>
                    <br>
                    Office of the Comptroller of the Currency <br>
                    Customer Assistance Group <br>
                    1301 McKinney Street, Suite 3450 <br>
                    Houston, TX 77010-9050 <br>
                    <br>
                    b. State member banks, branches and agencies of foreign banks (other than federal branches, federal agencies, and Insured 
                    State Branches of Foreign Banks), commercial lending companies owned or controlled by foreign banks, and organizations 
                    operating under section 25 or 25A of the Federal Reserve Act <br>
                    <br>
                    Federal Reserve Consumer Help Center <br>
                    P.O. Box. 1200 <br>
                    Minneapolis, MN 55480 <br>
                    <br>
                    c. Nonmember Insured Banks, Insured State Branches of Foreign Banks, and insured state savings associations <br>
                    <br>
                    FDIC Consumer Response Center <br>
                    1100 Walnut Street, Box #11 <br>
                    Kansas City, MO 64106 <br>
                    <br>
                    d. Federal Credit Unions <br>
                    <br>
                    National Credit Union Administration <br>
                    Office of Consumer Financial Protection (OCFP) <br>
                    Division of Consumer Compliance Policy and Outreach <br>
                    1775 Duke Street <br>
                    Alexandria, VA 22314 <br>
                  </div>
                  <div v-else>
                    2. En la medida en que no estén comprendidos en el punto 1 anterior: <br>
                    <br>
                    a. Bancos nacionales, asociaciones de ahorro federales y sucursales federales y agencias federales de bancos extranjeros <br>
                    <br>
                    Office of the Comptroller of the Currency <br>
                    Customer Assistance Group <br>
                    1301 McKinney Street, Suite 3450 <br>
                    Houston, TX 77010-9050 <br>
                    <br>
                    b. Bancos miembros del estado, sucursales y agencias de bancos extranjeros (que no sean 
                    sucursales federales, agencias federales, o Sucursales Estatales Aseguradas de Bancos Extranjeros),
                    compañías de préstamos comerciales de propiedad o controladas por bancos extranjeros y las
                    organizaciones que operan bajo la sección 25 o 25A de la Ley de la Reserva Federal (Federal Reserve Act) <br>
                    <br>
                    Federal Reserve Consumer Help Center <br>
                    P.O. Box. 1200 <br>
                    Minneapolis, MN 55480 <br>
                    <br>
                    c. Bancos Asegurados No Miembros, Sucursales Estatales Aseguradas de Bancos Extranjeros y asociaciones de ahorros estatales aseguradas <br>
                    <br>
                    FDIC Consumer Response Center <br>
                    1100 Walnut Street, Box #11 <br>
                    Kansas City, MO 64106 <br>
                    <br>
                    d. Cooperativas Federales de Crédito <br>
                    <br>
                    National Credit Union Administration <br>
                    Office of Consumer Financial Protection (OCFP) <br>
                    Division of Consumer Compliance Policy and Outreach <br>
                    1775 Duke Street <br>
                    Alexandria, VA 22314 <br>
                  </div>
                  <br>
                  <div v-if="isEnglish">
                    3. Air carriers <br>
                    <br>
                    Asst. General Counsel for Aviation Enforcement & Proceedings <br>
                    Aviation Consumer Protection Division <br>
                    Department of Transportation <br>
                    1200 New Jersey Avenue, S.E. <br>
                    Washington, DC 20423 <br>
                  </div>
                  <div v-else>
                    3. Compañías aéreas <br>
                    <br>
                    Asst. General Counsel for Aviation Enforcement & Proceedings <br>
                    Aviation Consumer Protection Division <br>
                    Department of Transportation <br>
                    1200 New Jersey Avenue, S.E. <br>
                    Washington, DC 20423 <br>
                  </div>
                  <br>
                  <div v-if="isEnglish">
                    4. Creditors Subject to the Surface Transportation Board <br>
                    <br>
                    Office of Proceedings, Surface Transportation Board <br>
                    Department of Transportation <br>
                    395 E Street, S.W. <br>
                    Washington, DC 20423 <br>
                  </div>
                  <div v-else>
                    4. Acreedores sujetos a la Junta de Transporte Terrestre (Surface Transportation Board) <br>
                    <br>
                    Office of Proceedings, Surface Transportation Board <br>
                    Department of Transportation <br>
                    395 E Street, S.W. <br>
                    Washington, DC 20423 <br>
                  </div>
                  <br>
                  <div v-if="isEnglish">
                    5. Creditors Subject to the Packers and Stockyards Act, 1921 <br>
                    <br>
                    Nearest Packers and Stockyards Administration area supervisor <br>
                  </div>
                  <div v-else>
                    5. Acreedores sujetos a la Ley de Empacadores y Corrales Ganaderos de 1921 (Packers and Stockyards Act, 1921) <br>
                    <br>
                    Supervisor de la oficina más cercana de la Packers and Stockyards Administration <br>
                  </div>
                  <br>
                  <div v-if="isEnglish">
                    6. Small Business Investment Companies <br>
                    <br>
                    Associate Deputy Administrator for Capital Access <br>
                    United States Small Business Administration <br>
                    409 Third Street, S.W., Suite 8200 <br>
                    Washington, DC 20416 <br>
                  </div>
                  <div v-else>
                    6. Compañías de Inversión en Pequeños Negocios <br>
                    <br>
                    Associate Deputy Administrator for Capital Access <br>
                    United States Small Business Administration <br>
                    409 Third Street, S.W., Suite 8200 <br>
                    Washington, DC 20416 <br>
                  </div>
                  <br>
                  <div v-if="isEnglish">
                    7. Brokers and Dealers <br>
                    <br>
                    Securities and Exchange Commission <br>
                    100 F Street, N.E. <br>
                    Washington, DC 20549 <br>
                  </div>
                  <div v-else>
                    7. Agentes y Distribuidores <br>
                    <br>
                    Securities and Exchange Commission <br>
                    100 F Street, N.E. <br>
                    Washington, DC 20549 <br>
                  </div>
                  <br>
                  <div v-if="isEnglish">
                    8. Federal Land Banks, Federal Lank Bank Associations, Federal Intermediate Credit Banks, and Production Credit Associations <br>
                    <br>
                    Farm Credit Administration <br>
                    1501 Farm Credit Drive <br>
                    McLean, VA 22102-5090 <br>
                  </div>
                  <div v-else>
                    8. Bancos Agrícolas Federales, Asociaciones de Bancos Agrícolas Federales, "Bancos Federales de Crédito Intermedio y Asociaciones 
                    de Crédito a la Producción" <br>
                    <br>
                    Farm Credit Administration <br>
                    1501 Farm Credit Drive <br>
                    McLean, VA 22102-5090 <br>
                  </div>
                  <br>
                  <div v-if="isEnglish">
                    9. Retailers, Finance Companies, and All Other Creditors Not Listed Above <br>
                    <br>
                    Federal Trade Commission <br>
                    Consumer Response Center <br>
                    600 Pennsylvania Avenue, N.W. <br>
                    Washington, DC 20580 <br>
                    (877) 382-4357 <br>
                  </div>
                  <div v-else>
                    9. Minoristas, Compañías Financieras y todos los demás acreedores no indicados anteriormente <br>
                    <br>
                    Federal Trade Commission <br>
                    Consumer Response Center <br>
                    600 Pennsylvania Avenue, N.W. <br>
                    Washington, DC 20580 <br>
                    (877) 382-4357 <br>
                  </div>
                </div> 
                <div 
                  v-if="readMoreSummaryOfRights"      
                  class="d-flex justify-center read-more-toggle mt-3 mb-6"            
                  @click="readMoreSummaryOfRights = false"
                > 
                  <v-icon
                    icon="mdi-chevron-up"
                    :title="isEnglish ? 'Hide' : 'Esconder'"
                  ></v-icon> 
                </div>
                <!-- Sign - Btn -->
                <div class="d-flex justify-center">
                  <v-btn 
                    :disabled="app.isSummaryOfRightsFormSigned ? true : false"
                    class="btn orange-btn" 
                    elevation="0"
                    @click="signSummaryOfRightsForm()"
                  >
                    <span v-if="isEnglish"> {{ app.isSummaryOfRightsFormSigned ? 'Signed' : 'Sign' }} </span>
                    <span v-else> {{ app.isSummaryOfRightsFormSigned ? 'Firmado' : 'Firmar' }} </span>
                  </v-btn>
                </div>
              </v-expansion-panel-text>
            </v-expansion-panel>
            <!-- Previous Employer Form -->
            <v-expansion-panel v-if="isTransportation">
              <v-expansion-panel-title> 
                <v-icon
                  v-if="app.isPreviousEmployerFormSigned"
                  class="mr-3 success-color"
                  icon="mdi-check-bold"
                  :title="isEnglish ? 'Signed' : 'Firmado'"
                ></v-icon>
                {{ isEnglish ? 'Previous Employer Request For Information' : 'Solicitud para Información de Empleador Anterior' }}
              </v-expansion-panel-title>
              <v-expansion-panel-text class="font-sm">
                <div> {{ isEnglish ? 'Please sign and date by the required fields.' : 'Por favor firme y llene la información requerida.' }} </div>
                <br>
                <div>
                  {{ isEnglish ? 'From: ' : 'De: ' }}	Labatt Food Service <br>
                  {{ isEnglish ? 'Street: ' : 'Dirección: ' }}	4500 Industry Park <br>
                  {{ isEnglish ? 'City, State: ' : 'Ciudad, Estado: ' }} San Antonio, TX. <br>
                  {{ isEnglish ? 'Zip: ' : 'Código postal: ' }} 78218 <br>
                  {{ isEnglish ? 'Phone: ' : 'Número de teléfono: ' }} (210) 661-04216 Ext. 2131 <br>
                  Fax: (210) 661-6303 <br>                  
                </div>
                <div 
                  v-if="!readMorePreviousEmployer1"
                  class="d-flex justify-center read-more-toggle mt-3 mb-4"
                  @click="readMorePreviousEmployer1 = true"
                > 
                  <span><i> {{ isEnglish ? 'Read more' : 'Leer más' }} </i></span>
                  <v-icon
                    class="ml-1"
                    icon="mdi-chevron-down"
                    :title="isEnglish ? 'Read more' : 'Leer más'"
                  ></v-icon>
                </div>
                <div v-if="readMorePreviousEmployer1">
                  <br>
                  <div><b> {{ isEnglish ? 'PREVIOUS EMPLOYER REQUEST FOR INFORMATION' : 'SOLICITUD DE INFORMACIÓN DEL EMPLEADOR ANTERIOR' }} </b></div>
                  <br>
                  <div>
                    {{ isEnglish ? 'Prospective Driver Name: ' : 'Nombre del conductor: ' }} _________________________ <br>
                    {{ isEnglish ? 'Social Security: ' : 'Número de seguro social: ' }} ____ /____ /______ <br>
                    {{ isEnglish ? 'Previous Employer: ' : 'Empleador anterior: ' }} _________________________ <br>
                    {{ isEnglish ? 'Street: ' : 'Dirección: ' }}	_________________________ <br>
                    {{ isEnglish ? 'City, State, Zip: ' : 'Ciudad, Estado, Código postal: ' }}	_________________________ <br>
                    {{ isEnglish ? 'Telephone: ' : 'Número de teléfono: ' }} (____)___________________ <br>
                    Fax #: (____)___________________  <br>
                  </div>             
                </div> 
                <div 
                  v-if="readMorePreviousEmployer1"      
                  class="d-flex justify-center read-more-toggle mt-3 mb-6"            
                  @click="readMorePreviousEmployer1 = false"
                > 
                  <v-icon
                    icon="mdi-chevron-up"
                    :title="isEnglish ? 'Hide' : 'Esconder'"
                  ></v-icon> 
                </div>
                <div v-if="isEnglish">
                  I hereby authorize the previous employer listed above to release the following information contained in Section 1 to 
                  Labatt Food Service pursuant to Section 391.23 of the Federal motor Carrier Safety Regulations.
                </div>
                <div v-else>
                  Por medio de la presente autorizo al empleador anterior mencionado previamente a divulgar la información contenida en 
                  la Sección 1 a Labatt Food Service, en conformidad con la Sección 391.23 de las Regulaciones de Seguridad Federales 
                  para Transportistas.
                </div> 
                <br>
                <div>
                  <!-- Date -->
                  <v-text-field
                    v-model="app.previousEmployerFormDate"
                    :rules="required"
                    :readonly="(loading || app.isPreviousEmployerFormSigned)"
                    type="date"
                    class="tf-padding"
                    :label="isEnglish ? 'Date' : 'Fecha'"
                    variant="outlined"
                    hide-details
                  ></v-text-field>
                  <!-- Signature -->
                  <div class="d-flex justify-center"> 
                    {{ isEnglish ? 'Please type your first and last name' : 'Por favor escriba su nombre y apellido' }}
                  </div>
                  <v-text-field
                    v-model="app.previousEmployerFormFullName"
                    :rules="required"
                    :readonly="(loading || app.isPreviousEmployerFormSigned)"
                    class="tf-padding input-signature"
                    variant="outlined"
                    hide-details
                  ></v-text-field>
                  <div class="d-flex justify-center mb-5"> 
                    {{ isEnglish ? 'Signature' : 'Firma' }} 
                  </div>
                </div>
                <div 
                  v-if="!readMorePreviousEmployer2"
                  class="d-flex justify-center read-more-toggle mt-3 mb-4"
                  @click="readMorePreviousEmployer2 = true"
                > 
                  <span><i> {{ isEnglish ? 'Read more' : 'Leer más' }} </i></span>
                  <v-icon
                    class="ml-1"
                    icon="mdi-chevron-down"
                    :title="isEnglish ? 'Read more' : 'Leer más'"
                  ></v-icon>
                </div>
                <div v-if="readMorePreviousEmployer2">
                  <br>
                  <hr>
                  <br>
                  <div>
                    <b> 
                      {{ isEnglish ? 'Section 1 (TO BE COMPLETED BY PREVIOUS EMPLOYER)' : 'Sección 1 (INGRESADO POR EL EMPLEADOR ANTERIOR)' }}
                    </b>
                  </div>
                  <br>
                  <div> {{ isEnglish ? 'Employed from: ' : 'Empleado desde: ' }} _____________________ </div>
                  <div> {{ isEnglish ? 'To: ' : 'Hasta: ' }} _____________________ </div>
                  <br>
                  <div> {{ isEnglish ? 
                           'Did this employee drive a commercial motor vehicle for you? ' : 
                           '¿Este empleado condujo un vehículo motorizado comercial para usted? ' }} 
                  </div>
                  <div> {{ isEnglish ? 'Yes' : 'Sí' }} ____________ No ____________ </div>
                  <br>
                  <div> {{ isEnglish ? 'Reason for leaving your company: ' : 'Razón por dejar su empresa: ' }} _________________________ </div>
                  <br>
                  <div v-if="isEnglish"> 
                    Accident information as defined under Section 390.5 for the previous 3 years 
                    (including date, city/town, # of injuries, # of fatalities, and any hazardous materials spilled)
                  </div>
                  <div v-else>
                    Información sobre accidentes según se define en la Sección 390.5 durante los 3 años anteriores
                    (incluya fecha, ciudad/pueblo, número de lesiones, número de muertes y cualquier material peligroso derramado)
                  </div>
                  <br>
                  <hr>
                  <br>
                  <hr>
                  <br>
                  <hr>
                  <br>
                  <div v-if="isEnglish"> 
                    Any other accident information retained pursuant to Section 390.15 (b) (2) or pursuant to company policy? (Please list) 
                  </div>
                  <div v-else>
                    ¿Alguna otra información sobre accidentes archivada en conformidad con la Sección 390.15 (b) (2) o en conformidad con 
                    la política de la empresa? (Por favor explique)
                  </div>
                  <br>
                  <hr>
                  <br>
                  <div>
                    <b> 
                      {{ isEnglish ? 'Section 2 (TO BE COMPLETED BY PREVIOUS EMPLOYER)' : 'Sección 2 (A COMPLETAR POR EL EMPLEADOR ANTERIOR)' }}
                    </b>
                  </div>
                </div> 
                <div 
                  v-if="readMorePreviousEmployer2"      
                  class="d-flex justify-center read-more-toggle mt-3 mb-3"            
                  @click="readMorePreviousEmployer2 = false"
                > 
                  <v-icon
                    icon="mdi-chevron-up"
                    :title="isEnglish ? 'Hide' : 'Esconder'"
                  ></v-icon> 
                </div>
                <div v-if="isEnglish">
                  I hereby authorize the previous employer listed above to release the information requested in section 2 concerning 
                  alcohol and controlled substance testing records to Labatt Food Service. This information is being requested in 
                  compliance with Section 40.25 and Section 382.405 (f) and (h).
                </div>
                <div v-else>
                  Por medio de la presente autorizo al empleador anterior mencionado previamente a divulgar la información contenida 
                  en la Sección 2 sobre registros de pruebas de alcohol y sustancias controladas a Labatt Food Service. Esta información 
                  se solicita en cumplimiento con la Sección 40.25 y la Sección 382.405 (f) y (h).
                </div>
                <br>
                <div>
                  <!-- Date -->
                  <v-text-field
                    v-model="app.previousEmployerFormDate"
                    :rules="required"
                    :readonly="(loading || app.isPreviousEmployerFormSigned)"
                    type="date"
                    class="tf-padding"
                    :label="isEnglish ? 'Date' : 'Fecha'"
                    variant="outlined"
                    hide-details
                  ></v-text-field>
                  <!-- Signature -->
                  <div class="d-flex justify-center"> 
                    {{ isEnglish ? 'Please type your first and last name' : 'Por favor escriba su nombre y apellido' }}
                  </div>
                  <v-text-field
                    v-model="app.previousEmployerFormSignature"
                    :rules="required"
                    :readonly="(loading || app.isPreviousEmployerFormSigned)"
                    class="tf-padding input-signature"
                    variant="outlined"
                    hide-details
                  ></v-text-field>
                  <div class="d-flex justify-center mb-5"> 
                    {{ isEnglish ? 'Signature' : 'Firma' }} 
                  </div>
                </div>
                <div 
                  v-if="!readMorePreviousEmployer3"
                  class="d-flex justify-center read-more-toggle mt-3 mb-4"
                  @click="readMorePreviousEmployer3 = true"
                > 
                  <span><i> {{ isEnglish ? 'Read more' : 'Leer más' }} </i></span>
                  <v-icon
                    class="ml-1"
                    icon="mdi-chevron-down"
                    :title="isEnglish ? 'Read more' : 'Leer más'"
                  ></v-icon>
                </div>
                <div v-if="readMorePreviousEmployer3">
                  <br>
                  <div v-if="isEnglish"> 1. Has this person had an alcohol test with a result of 0.04 or higher alcohol concentration? </div>
                  <div v-else> 1. ¿Ha tenido esta persona una prueba de alcohol con un resultado de 0.04 o más de concentración de alcohol? </div>
                  <div> {{ isEnglish ? 'Yes' : 'Sí' }} ____________ No ____________ </div>
                  <br>
                  <div v-if="isEnglish"> 2. Has this person had a verified positive drug test? </div>
                  <div v-else> 2. ¿Ha tenido esta persona una prueba de drogas positiva verificada? </div>
                  <div> {{ isEnglish ? 'Yes' : 'Sí' }} ____________ No ____________ </div>
                  <br>
                  <div v-if="isEnglish"> 3. Has this person refused to be tested (including verified adulterated or substituted drug test results)? </div>
                  <div v-else> 3. ¿Se ha negado esta persona a hacerse alguna prueba (incluyendo resultados verificados de pruebas de drogas adulteradas o sustituidas)? </div>
                  <div> {{ isEnglish ? 'Yes' : 'Sí' }} ____________ No ____________ </div>
                  <br>
                  <div v-if="isEnglish"> 4. Has this person committed other violations of DOT agency drug and alcohol testing regulations? </div>
                  <div v-else> 4. ¿Ha cometido esta persona otras violaciones a las regulaciones de pruebas de drogas y alcohol del Departamento de Transporte (DOT)? </div>
                  <div> {{ isEnglish ? 'Yes' : 'Sí' }} ____________ No ____________ </div>
                  <br>
                  <div v-if="isEnglish"> 5. If this person has violated a DOT drug and alcohol regulation, do you have documentation of the employee's successful completion 
                    of DOT return-to-duty requirements, including follow up test? (Please send this documentation back with this form, if applicable) 
                  </div>
                  <div v-else> 5. Si esta persona ha violado alguna regulación sobre drogas y alcohol del DOT, ¿tiene usted documentación que demuestre que el empleado 
                    completó los requisitos de regreso al trabajo del DOT, incluyendo la prueba de seguimiento? (De ser así, envíe esta documentación junto con este 
                    formulario)
                  </div>
                  <div> {{ isEnglish ? 'Yes' : 'Sí' }} ____________ No ____________ </div>
                  <br>
                  <div v-if="isEnglish"> 
                    In answering these questions, include any drug or alcohol testing information obtained from previous employers under 
                    section 40.25 or other applicable DOT agency regulations.
                  </div>
                  <div v-else>
                    Al responder estas preguntas, incluya cualquier información sobre pruebas de drogas o alcohol obtenida de empleadores anteriores 
                    según la sección 40.25 u otras regulaciones aplicables de la agencia DOT.
                  </div>
                  <br>
                  <div> 
                    {{ isEnglish ? 
                       'Signature of former employer or representative completing this form: ' : 
                       'Firma del empleador o representante anterior que completa este formulario: ' }} ____________________________ 
                  </div>
                  <div> {{ isEnglish ? 'Date: ' : 'Fecha: ' }} _________________ </div>
                </div> 
                <div 
                  v-if="readMorePreviousEmployer3"      
                  class="d-flex justify-center read-more-toggle mt-3 mb-3"            
                  @click="readMorePreviousEmployer3 = false"
                > 
                  <v-icon
                    icon="mdi-chevron-up"
                    :title="isEnglish ? 'Hide' : 'Esconder'"
                  ></v-icon> 
                </div>
                <br>
                <!-- Sign - Btn -->
                <div class="d-flex justify-center">
                  <v-btn 
                    :disabled="app.isPreviousEmployerFormSigned ? true : false"
                    class="btn orange-btn" 
                    elevation="0"
                    type="submit"
                    @click="signPreviousEmployerForm()"
                  >
                    <span v-if="isEnglish"> {{ app.isPreviousEmployerFormSigned ? 'Signed' : 'Sign' }} </span>
                    <span v-else> {{ app.isPreviousEmployerFormSigned ? 'Firmado' : 'Firmar' }} </span>
                  </v-btn>
                </div>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>    
          <br><br><br>
          <!-- Btns -->
          <v-row>
            <v-col>
              <!-- Save & Continue - Btn -->
              <div class="d-flex justify-end">
                <v-btn 
                  class="btn orange-btn" 
                  :width="113" 
                  elevation="0"
                  type="submit"
                >
                  {{ isEnglish ? 'Continue' : 'Continuar' }}
                </v-btn>
              </div>        
            </v-col>
          </v-row>
          <br>
        </v-form>
      </v-col>
    </v-row>  
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/stores/store'
import { useCookies } from 'vue3-cookies'
import api from '@/api'
import ApplicationProgressComponent from '@/components/ApplicationProgressComponent.vue'
import JobTitleComponent from '@/components/JobTitleComponent.vue'
import type { ApplicationAdditionalForms, ApplicationPersonalInfo } from '@/types'

const loading = ref(false)
const error = ref(false)
const errorMessage = ref('')
const required = [(value: string) => !!value || 'Required']
const router = useRouter()
const store = useStore()
const { cookies } = useCookies()
const email = ref('')

const panel = ref([])
const personalInformation = ref({} as ApplicationPersonalInfo)
const app = ref({} as ApplicationAdditionalForms)
const readMoreConsumerReports1 = ref(false)
const readMoreConsumerReports2 = ref(false)
const readMoreConsumerReports3 = ref(false)
const readMoreSummaryOfRights = ref(false)
const readMorePreviousEmployer1 = ref(false)
const readMorePreviousEmployer2 = ref(false)
const readMorePreviousEmployer3 = ref(false)

const isEnglish = computed(() => {
  return store.language === 'english'
})

const phase = computed(() => {
  return store.applicationPhase
})

const section = computed(() => {
  if (isOffice.value) {
    return 8
  }
  if (phase.value === 0 && isTransportation.value) {
    return 10
  }
  return 9
})

const isTransportation = computed(() => {
  return store.selectedJobPosting?.positionType === 'Transportation'
})

const isOffice = computed(() => {
  return (store.selectedJobPosting?.positionType === 'Office' || 
    store.selectedJobPosting?.positionType === 'DSMOffice') && 
    store.selectedJobPosting?.isResume
})

const reload = async () => {
  const userEmail = cookies.get('labatt-user-email')
  if (userEmail) {
    email.value = userEmail
  } else {
    router.push('/login')
  }
  window.scrollTo({ top: 0, behavior: 'smooth' })
  loading.value = true
  error.value = false
  errorMessage.value = ''
  try {
    if (store.applicationId) {
      const r1 = await api.getApplicationPersonalInfo(store.applicationId)
      if (r1) {
        personalInformation.value = r1
      }
      const r2 = await api.getApplicationAdditionalForms(store.applicationId)
      if (r2) {
        app.value = r2
      } else {
        app.value.id = store.applicationId
        app.value.consumerReportFormDate = new Date().toISOString().substring(0, 10)
        app.value.previousEmployerFormDate = new Date().toISOString().substring(0, 10)
      }
    }
  } catch (e) {
    showError(isEnglish.value ? 'Please reload the page and try again' : 'Por favor actualice la página y vuelva a intentarlo')
  } finally {
    loading.value = false
  }
}
onMounted(reload)

const showError = (message: string) => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  error.value = true
  errorMessage.value = message
  loading.value = false
}

const formatDate = (d: string | null | undefined) => {
  if (d && d !== '0001-01-01') {
    return d.substring(5, 7) + '/' + d.substring(8, 10) + '/' + d.substring(0, 4)
  }
  return ''
}

const formatSSN = (s: number | null) => {
  if (s) {
    const str = s.toString()
    if (str.length === 7) {
      return 'XXX' + '-' + 'XX' + '-' + str.substring(3, 7)
    } else if (str.length === 8) {
      return 'XXX' + '-' + 'XX' + '-' + str.substring(4, 8)
    } else {
      return 'XXX' + '-' + 'XX' + '-' + str.substring(5, 9)
    }
  }
  return s
}

const resetPanel = () => {
  error.value = false
  errorMessage.value = ''
  panel.value = []
}

const signConsumerReportForm = () => {
  if (app.value.consumerReportFormDate && app.value.consumerReportFormFullName && 
      app.value.consumerReportFormSignature1 && app.value.consumerReportFormSignature2 &&
      app.value.consumerReportFormSignature3) {

    app.value.isConsumerReportFormSigned = true
    resetPanel()
  } else {    
    showError(isEnglish.value ? 
      'Please enter your name and signature in the Disclosure and Authorization of Consumer Reports form' : 
      'Por favor escriba su nombre y firme en el Formulario de Consentimiento y Autorización de Investigación para Reportes del Consumidor')
  }
}

const signSummaryOfRightsForm = () => {
  app.value.isSummaryOfRightsFormSigned = true
  resetPanel()
}

const signPreviousEmployerForm = () => {
  if (app.value.previousEmployerFormDate && app.value.previousEmployerFormFullName && 
      app.value.previousEmployerFormSignature) {

    app.value.isPreviousEmployerFormSigned = true
    resetPanel()
  } else {
    showError(isEnglish.value ? 
      'Please enter your name and signature in the Previous Employer Request For Information form' : 
      'Por favor escriba su nombre y firme en el formulario de la Solicitud para Información de Empleador Anterior')
  }
}

const isFormValid = () => {
  if (!app.value.isConsumerReportFormSigned) {
    showError(isEnglish.value ? 
      'Please sign the Disclosure and Authorization of Consumer Reports form' : 
      'Por favor firme en el Formulario de Consentimiento y Autorización de Investigación para Reportes del Consumidor')
    return false
  } else if (!app.value.isSummaryOfRightsFormSigned) {
    showError(isEnglish.value ? 
      'Please sign the Summary of Rights under FCRA form' : 
      'Por favor firme en el formulario de Resumen de sus Derechos en Virtud de la Ley de Informe Justo de Crédito')
    return false
  } else if (isTransportation.value && !app.value.isPreviousEmployerFormSigned) {
    showError(isEnglish.value ? 
      'Please sign the Previous Employer Request For Information form' : 
      'Por favor firme en el formulario de la Solicitud para Información de Empleador Anterior')
    return false
  }
  return true
}

const saveAndContinue = async () => {
  loading.value = true
  error.value = false
  errorMessage.value = ''
  if (isFormValid()) {
    app.value.email = email.value 
    app.value.language = store.language   
    if (app.value.isPreviousEmployerFormSigned) {
      // do nothing
    } else {
      app.value.isPreviousEmployerFormSigned = false
    }
    try {
      const r = await api.updateApplicationAdditionalForms(app.value)
      if (r === 'Success') {
        router.push('/application/submitted')                
      } else {
        showError(r)
      }
    } catch (e) {
      showError(isEnglish.value ? 'Error saving application additional forms' : 'Error al guardar la información de formas adicionales')
    }
  }  
}
</script>

<style>
.read-more-toggle {
  font-size: 13px;
}
.read-more-toggle:hover {
  cursor: pointer;
}
</style>
