<template>
  <div class="dashboard">
    <!-- Logout -->
    <v-container class="container-view logout-view elevation-2">
      <LogoutComponent :view="isEnglish ? 'Dashboard' : 'Inicio'" />
    </v-container>
    <!-- View -->
    <v-container class="container-view dashboard-inner elevation-2">
      <!-- Loading -->
      <div v-if="loading">
        <br>
        <div class="d-flex justify-center">
          <v-progress-circular
            class="orange-spinner"
            indeterminate
          ></v-progress-circular>
        </div>      
      </div>
      <div v-else>
        <!-- Alert -->
        <div 
          v-if="error"
          class="d-flex justify-center"
        >
          <div class="alert">
            <v-alert
              border="start"
              type="error"
              variant="tonal"
            >
              {{ errorMessage }}
            </v-alert>
          </div> 
        </div>
        <br>
        <div v-if="currentApp">
          <!-- Current Application -->
          <div class="d-flex justify-center">
            <v-card
              class="card"
              variant="outlined"
              elevation="0"
            >
              <v-card-text>
                <div class="d-flex justify-center font-sm mb-1"> 
                  <span v-if="currentPhase === 1">
                    {{ isEnglish ? 'Current Submission' : 'Solicitud Actual' }}
                  </span>
                  <span v-else>
                    {{ isEnglish ? 'Current Application' : 'Aplicación Actual' }}
                  </span>
                </div>
                <div class="d-flex justify-center mb-1"> 
                  <div class="title-bold"> {{ currentApp.jobTitle }} </div>
                </div>
                <div 
                  v-if="phase === 0"
                  class="mt-5 ml-4 mr-4"
                >
                  <ApplicationProgressComponent :phase="phase" :section="currentSection" />
                </div>
                <div class="d-flex justify-center mb-3 mt-2"> 
                  <span class="subtitle"> {{ isEnglish ? 'Started on ' : 'Fecha de inicio ' }} {{ currentApp.applicationDate }} </span>
                </div>
                <br>
                <div 
                  v-if="canContinueApplication" 
                  class="d-flex justify-center"
                >
                  <!-- Continue Application - Btn -->
                  <v-btn 
                    :loading="loading"
                    class="btn orange-btn mr-15" 
                    elevation="0"
                    type="submit"
                    @click="continueApplication()"
                  >
                    {{ isEnglish ? 'Continue' : 'Continuar' }}
                  </v-btn>
                  <!-- Cancel Application - Btn -->
                  <v-btn 
                    :loading="loading"
                    class="btn orange-btn"
                    elevation="0"
                    type="submit"
                    @click="cancelApplication()"
                  >
                    {{ isEnglish ? 'Cancel' : 'Cancelar' }}
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </div> 
          <br>
          <div class="d-flex justify-center font-sm"> 
            {{ isEnglish ? 
              'Unfinished applications will be deleted after 30 days' : 
              'Las aplicaciones incompletas se eliminarán después de 30 días' }}
          </div>
        </div>
        <div v-else-if="hasToTakeActivities && isInternal"></div>
        <div v-else-if="hasPreviousAppsInPast90Days"></div>
        <div v-else>
          <div v-if="jobPosting">
            <!-- Selected Job -->
            <div class="d-flex justify-center">
              <v-card
                class="card"
                variant="outlined"
                elevation="0"
              >
                <v-card-text>
                  <div class="d-flex justify-center font-sm"> 
                    {{ isEnglish ? 'Selected Job' : 'Empleo Seleccionado' }}
                  </div>
                  <div class="d-flex justify-center mb-2"> 
                    <div class="title-bold"> {{ jobPosting.title }} </div>
                  </div>
                  <br>
                  <!-- Start New Application - Btn -->
                  <div class="d-flex justify-center">
                    <v-btn 
                      :loading="loading"
                      class="btn orange-btn" 
                      elevation="0"
                      type="submit"
                      @click="createApplication()"
                    >
                      <span v-if="currentPhase === 1">
                        {{ isEnglish ? 'Start new submission' : 'Iniciar una solicitud nueva' }}
                      </span>
                      <span v-else>
                        {{ isEnglish ? 'Start new application' : 'Iniciar una aplicación nueva' }}
                      </span>
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </div>  
          </div>
          <div v-else>
            <div class="d-flex justify-center"> 
              <div class="title-bold"> {{ isEnglish ? 'Apply to a position' : 'Aplicar a empleo' }} </div>
            </div>
          </div>
          <!-- Open Jobs - Btn -->
          <div class="d-flex justify-center mt-4">
            <a :href="jobSearchLink">
              <v-btn 
                class="btn font-sm"
                variant="plain"
                elevation="0"
                type="submit"
              >
                {{ isEnglish ? 'View all available jobs' : 'Ver todos los empleos disponibles' }}
              </v-btn>
            </a>          
          </div>
          <br>
        </div>
        <div v-if="previousApps.length > 0">
          <br>        
          <!-- Title -->
          <div class="d-flex justify-center"> 
            <div class="title-bold"> {{ isEnglish ? 'Previous Applications' : 'Aplicaciones Previas' }}  </div>
          </div>
          <!-- Applications -->
          <div 
            v-for="pa in previousApps" 
            :key="pa.id"
          >    
            <br>
            <div class="d-flex justify-center">
              <v-card
                class="card font-sm"
                variant="outlined"
                elevation="0"
              >
                <v-card-item>
                  <v-card-title class="font-sm">
                    <b> {{ pa.jobTitle }} </b>
                  </v-card-title>
                  <v-card-subtitle>
                    {{ pa.location }}
                  </v-card-subtitle>
                </v-card-item>
                <v-card-text>
                  {{ isEnglish ? 'Date: ' : 'Fecha: ' }} 
                  {{ pa.applicationDate }}
                  <br>
                  {{ isEnglish ? 'Complete: ' : 'Finalizada: ' }} 
                  <span v-if="isEnglish">
                    {{ pa.status === 'Completed' ? 'Yes' : 'No' }}
                  </span>
                  <span v-else>
                    {{ pa.status === 'Completed' ? 'Sí' : 'No' }}
                  </span>
                  <div 
                    v-if="hasToTakeActivities && isInternal && pa.id === store.applicationId" 
                    class="d-flex justify-center mt-2"
                  >
                    <!-- Take Activities - Btn -->
                    <v-btn 
                      :loading="loading"
                      class="btn orange-btn" 
                      elevation="0"
                      type="submit"
                      @click="startActivities()"
                    >
                      {{ isEnglish ? 'Take Activities' : 'Tomar Actividades' }}
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </div>          
          </div> 
          <br> 
          <!-- Message -->
          <div class="d-flex justify-center font-sm">      
            {{ isEnglish ? 
              'Only one application can be submitted per 90 day period' : 
              'Solo una aplicación puede ser enviada cada 90 días' }}
          </div>        
        </div>
      </div>    
    </v-container>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import LogoutComponent from '@/components/LogoutComponent.vue'
import ApplicationProgressComponent from '@/components/ApplicationProgressComponent.vue'
import { JobPosting, ApplicationInfo, NewApplication, CancelApplication, ContinueApplication,
  ApplicationSection } from '@/types'
import api from '@/api'
import { useCookies } from 'vue3-cookies'
import { useStore } from '@/stores/store'

const loading = ref(false)
const error = ref(false)
const errorMessage = ref('')
const email = ref('')
const jobSearchLink = ref('')
const jobPosting = ref(null as JobPosting | null)
const currentApp = ref(null as ApplicationInfo | null)
const previousApps = ref([] as ApplicationInfo[])
const hasToTakeActivities = ref(false)
const router = useRouter()
const { cookies } = useCookies()
const store = useStore()
const currentSection = ref(0)
const isInternal = ref(false)
const hasPreviousAppsInPast90Days = ref(false)
const currentPhase = ref(0)
const canContinueApplication = ref(true)

const isEnglish = computed(() => {
  return store.language === 'english'
})

const phase = computed(() => {
  return store.applicationPhase
})

const reload = async () => {
  loading.value = true
  hasToTakeActivities.value = false
  isInternal.value = false
  const userEmail = cookies.get('labatt-user-email')
  if (userEmail) {
    email.value = userEmail
    previousApps.value = await api.getPreviousApplications(email.value)
    if (previousApps.value) {
      const app = previousApps.value[0]
      if (app) {
        const today = new Date(new Date().toDateString())
        const past90Days = new Date(today.getTime() - (90 * 86400000))
        const appDate = new Date(app.applicationDate)
        if (appDate > past90Days) {
          hasPreviousAppsInPast90Days.value = true
        }
      }
    }
    currentApp.value = await api.getCurrentApplication(email.value)
    isInternal.value = await api.getIsInternal()
    if (currentApp.value) {
      store.setApplicationId(currentApp.value.id)
    } else if (previousApps.value) {
      const app = previousApps.value[0]
      hasToTakeActivities.value = await api.getTakeActivities(email.value)
      if (app && hasToTakeActivities.value && isInternal.value) {
        store.setApplicationId(app.id)
        store.setJobPostingId(app.jobPostingId)
      }
    } else {
      store.setApplicationId('')
    }
    if (currentApp.value) {
      const mpApplicant = await api.getMultiPhaseApplicant(currentApp.value.id)
      if (mpApplicant) {
        currentPhase.value = 1

        const statuses = ['Review Phase 1', 'Schedule Phase 2', 'Phase 2 Completed', 'Rejected']
        if (statuses.includes(mpApplicant.status)) {
          canContinueApplication.value = false
        }
        if (mpApplicant.status === 'Phase 2 Scheduled') {
          currentPhase.value = 2
        } 
      }
      jobPosting.value = await api.getJobPosting(currentApp.value.jobPostingId)
      const isTransportation = jobPosting.value?.positionType === 'Transportation'
      const isOffice = (jobPosting.value?.positionType === 'Office' || jobPosting.value?.positionType === 'DSMOffice') && jobPosting.value?.isResume
      const p = currentApp.value.lastSavedPage 
      if (currentPhase.value === 1) {
        if (p <= 6) {
          currentSection.value = p
        } else if (isTransportation) {
          if (p === 7) {
            currentSection.value = p - 1
          } else if (p === 9) {
            currentSection.value = p - 2
          }
        } else {
          if (p === 9) {
            currentSection.value = p - 3
          }
        }
      } else if (currentPhase.value === 2) {
        if (p <= 5) {
          currentSection.value = p
        } else if (isOffice) {
          if (p === 6) {
            currentSection.value = p - 1
          } else {
            currentSection.value = p - 2
          }
        } else if (p === 6) {
          currentSection.value = p
        } else {
          currentSection.value = p - 1
        }
      } else {
        if (isTransportation || currentApp.value.lastSavedPage <= 5) {
          currentSection.value = p 
        } else if (isOffice) {
          if (p === 6) {
            currentSection.value = p - 1
          } else {
            currentSection.value = p - 2
          }
        } else if (p === 6) {
          currentSection.value = p
        } else {
          currentSection.value = p - 1
        }
      }
    } else if (store.jobPostingId) {
      jobPosting.value = await api.getJobPosting(store.jobPostingId)
      if (jobPosting.value?.isMultiPhase) {
        currentPhase.value = 1
      }
    } else {
      jobPosting.value = null
    }
    store.setSelectedJobPosting(jobPosting.value)
    store.setApplicationPhase(currentPhase.value)
    store.setApplicationSections([])
    store.setIsApplicationReview(false)
    jobSearchLink.value = await api.getJobSearchLink()
  } else {
    router.push('/login')
  }
  loading.value = false
}
onMounted(reload)

const showError = (message: string) => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  error.value = true
  errorMessage.value = message
}

const createApplication = async () => {
  loading.value = true
  error.value = false
  errorMessage.value = ''
  try {
    const user: NewApplication = {
      email: email.value,
      jobPostingId: store.jobPostingId,
      webHistory: store.webHistory,
      language: store.language
    }
    const r = await api.createApplication(user)
    if (r === 'Success') {
      currentApp.value = await api.getCurrentApplication(email.value)
      if (currentApp.value) {
        const mpApplicant = await api.getMultiPhaseApplicant(currentApp.value.id)
        if (mpApplicant) {
          currentPhase.value = 1
          store.setApplicationPhase(currentPhase.value)
        }
        store.setApplicationId(currentApp.value.id)
        router.push('/application/personal-information')
      } else {
        showError(isEnglish.value ? 'Could not load your new application' : 'No se pudo cargar su nueva aplicación')
        loading.value = false
      }
    } else {
      showError(r)
      loading.value = false
    }
  } catch (e) {
    showError(isEnglish.value ? 'Please reload the page and try again' : 'Por favor actualice la página y vuelva a intentarlo')
    loading.value = false
  }
}

const cancelApplication = async () => {
  loading.value = true
  error.value = false
  errorMessage.value = ''
  try {
    const app: CancelApplication = {
      email: email.value,
      applicationId: currentApp.value?.id ?? '',
      language: store.language
    }
    const r = await api.cancelApplication(app)
    if (r === 'Success') {
      reload()
    } else {
      showError(r)
      loading.value = false
    }
  } catch (e) {
    showError(isEnglish.value ? 'Please reload the page and try again' : 'Por favor actualice la página y vuelva a intentarlo')
    loading.value = false
  }
}

const continueApplication = async () => {
  loading.value = true
  error.value = false
  errorMessage.value = ''
  try {
    const app: ContinueApplication = {
      email: email.value,
      applicationId: currentApp.value?.id ?? '',
      language: store.language
    }
    const r = await api.continueApplication(app)
    if (r === 'Success') {
      if (phase.value === 0) {
        if (currentApp.value?.lastSavedPage) {
          var sections = store.applicationSections
          for (let i = 0; i < currentApp.value.lastSavedPage; i++) {
            var s : ApplicationSection | null = null 
            if (i === 1) {
              s = {
                id: 'personal-information',
                title: 'Personal Information',
                isReviewed: false
              }
            } else if (i === 2) {
              s = {
                id: 'work-eligibility',
                title: 'Work Eligibility',
                isReviewed: false
              }
            } else if (i === 3) {
              s = {
                id: 'education-military',
                title: 'Education & Military',
                isReviewed: false
              }
            } else if (i === 4) {
              s = {
                id: 'previous-employment',
                title: 'Previous Employment',
                isReviewed: false
              }
            } else if (i === 5) {
              s = {
                id: 'work-experience',
                title: 'Work Experience',
                isReviewed: false
              }
            } else if (i === 6) {
              s = {
                id: 'background-questions',
                title: 'Background Questions',
                isReviewed: false
              }
            } else if (i === 7 && jobPosting.value?.positionType === 'Transportation') {
              s = {
                id: 'driver-information',
                title: 'Driver Information',
                isReviewed: false
              }
            } else if (i === 8) {
              s = {
                id: 'eeo-aa',
                title: 'Equal Employment Opportunity & Affirmative Action',
                isReviewed: false
              }
            } 
            if (s != null && !sections.map(ss => ss.id).includes(s.id)) {
              sections.push(s)
            }
          }
          store.setApplicationSections(sections)
        }

        switch(currentApp.value?.lastSavedPage) {
          case 1:
            router.push('/application/personal-information')
            break
          case 2:
            router.push('/application/work-eligibility')
            break
          case 3:
            router.push('/application/education-military')
            break
          case 4:
            router.push('/application/previous-employment')
            break
          case 5:
            router.push('/application/work-experience')
            break
          case 6:
            router.push('/application/background-questions')
            break
          case 7:
            router.push('/application/driver-information')
            break
          case 8:
            router.push('/application/eeo-aa')
            break
          case 9:
            router.push('/application/review')
            break
          default:
            router.push('/application/personal-information')
        }
      } else {
        router.push('/application/personal-information')
      }
    } else {
      showError(r)
      loading.value = false
    }
  } catch (e) {
    showError(isEnglish.value ? 'Please reload the page and try again' : 'Por favor actualice la página y vuelva a intentarlo')
    loading.value = false
  }
}

const startActivities = async () => {
  router.push('/activities')
}
</script>

<style>
.dashboard {
  background-color: #f3f2f2;
}
.dashboard-inner {
  background-color: white;
  min-height: 81.5dvh;
}
.logout-view {
  background-color: white;
}
.card {
  width: 100%;
  background-color: #eeeeee;
  border-color: lightgray;
}
.alert {
  width: 100%;
}
@media screen and (min-width: 767px) {
  .dashboard-inner {
    min-height: 85dvh;
  }
}
@media screen and (min-width: 1025px) {
  .dashboard-inner {
    min-height: 81.5dvh;
  }
}
</style>
